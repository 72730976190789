import { Notification } from '../models/data';
import BaseService from './BaseService';

export interface NotificationPaginationResponse {
  notifications: Notification[];
  notificationCount: number;
  pageCount: number;
  page: number;
  perPage: number;
}

export class NotificationService extends BaseService {
  async getNotifications(page?: number, status?: string) {
    const currentPage: number = page || 1;

    let params = {};

    if (page) {
      params['page'] = currentPage;
    }

    if (status) {
      params['status'] = status;
    }

    return (
      await this.getJson<NotificationPaginationResponse>('/notifications', {
        params,
      })
    ).data;
  }

  async getNotification(id: string) {
    return (await this.getJson<Notification>('/notifications/' + id)).data;
  }

  async store(notification: Partial<Notification>) {
    return (await this.postJson<Notification>('/notifications', notification)).data;
  }

  async update(notification: Partial<Notification>) {
    return (await this.putJson<Notification>('/notifications/' + notification.id, notification)).data;
  }

  async delete(notification: Notification) {
    return (await this.deleteJson<Notification>('/notifications/' + notification.id)).data;
  }
}

export default new NotificationService();
