import { useFormik } from 'formik';
import { AddIcon, Box, Button, FormControl, Heading, Input, MinusIcon, useMediaQuery, VStack } from 'native-base';
import React, { useCallback, useMemo } from 'react';
import { User } from '../../models/data';
import { useAppDispatch, useAppSelector } from '../../store';
import { usersActions } from '../../store/actions';
import { searchSchema } from './common';

export interface UserSearchProps {
  formUsers: User[];
  onAddUser: (user: User) => void;
  onRemoveUser: (user: User) => void;
  error?: any;
  editable?: boolean;
  disabled?: boolean;
}

export const UserSearch: React.FC<UserSearchProps> = ({
  formUsers,
  error,
  disabled,
  editable,
  onAddUser,
  onRemoveUser,
}) => {
  const dispatch = useAppDispatch();

  const [isSmallScreen] = useMediaQuery({
    maxHeight: 480,
  });

  const userState = useAppSelector((state) => state.users);
  const { loading: usersLoading, users } = userState;

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    validationSchema: searchSchema,
    onSubmit: (values) => {
      searchUsers(values.search);
    },
  });

  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      return !formUsers
        .map((user) => {
          return user.id;
        })
        .includes(user.id);
    });
  }, [formUsers, users]);

  const searchUsers = useCallback((status: string) => {
    dispatch(usersActions.getUsers({ page: 1, status }));
  }, []);

  const addUser = useCallback(
    (user: User) => () => {
      onAddUser(user);
    },
    [onAddUser],
  );

  const removeUser = useCallback(
    (user: User) => () => {
      onRemoveUser(user);
    },
    [onRemoveUser],
  );

  return (
    <FormControl mr={isSmallScreen ? 0 : 2} isInvalid={!!error} flex={1}>
      <FormControl.Label>
        <Heading size="sm">To (Users)</Heading>
      </FormControl.Label>
      <FormControl.ErrorMessage>{error}</FormControl.ErrorMessage>
      <Box>
        <FormControl isInvalid={formik.touched.search && formik.errors.search ? true : false}>
          <FormControl.ErrorMessage>
            {formik.touched.search && formik.errors.search ? formik.errors.search : null}
          </FormControl.ErrorMessage>
          <Input
            mb={2}
            placeholder="Search users..."
            onChangeText={formik.handleChange('search')}
            autoCapitalize="none"
            returnKeyType="default"
            value={formik.values.search}
            onSubmitEditing={() => formik.handleSubmit()}
            isDisabled={usersLoading || disabled || !editable}
            InputRightElement={
              <Button
                onPress={() => formik.handleSubmit()}
                colorScheme="secondary"
                isDisabled={usersLoading || disabled || !editable}
                isLoading={usersLoading}
              >
                SEARCH
              </Button>
            }
          />
        </FormControl>
        <VStack space={2} mb={formUsers.length > 0 ? 2 : 0}>
          {formUsers.length > 0 &&
            formUsers.map((user) => {
              return (
                <Button
                  key={user.id}
                  borderRadius={4}
                  size="sm"
                  onPress={removeUser(user)}
                  leftIcon={<MinusIcon />}
                  colorScheme="secondary"
                  isDisabled={usersLoading || disabled || !editable}
                  justifyContent="flex-start"
                >
                  {`${user.firstName} ${user.lastName} (${user.email})`}
                </Button>
              );
            })}
        </VStack>
        <VStack space={2}>
          {filteredUsers.map((user) => {
            return (
              <Button
                key={user.id}
                borderRadius={4}
                size="sm"
                onPress={addUser(user)}
                isDisabled={usersLoading || disabled || !editable}
                variant="outline"
                colorScheme="secondary"
                leftIcon={<AddIcon />}
                justifyContent="flex-start"
              >
                {`${user.firstName} ${user.lastName} (${user.email})`}
              </Button>
            );
          })}
        </VStack>
      </Box>
    </FormControl>
  );
};
