import { NavigationContainer } from '@react-navigation/native';
import React, { useEffect, useMemo } from 'react';
import { RoleNames } from '../constants/Roles';
import { SplashScreen } from '../screens';
import { useAppDispatch, useAppSelector } from '../store';
import { authActions } from '../store/actions';
import { AppNavigation } from './AppNavigation';
import { AuthNavigation } from './AuthNavigation';
import { UnauthorizedNavigation } from './UnauthorizedNavigation';

const linking = {
  config: {
    screens: {
      Redirect: 'redirect',
      Auth: {
        screens: {
          Login: 'login',
        },
      },
      App: {
        screens: {
          Drawer: {
            screens: {
              Profile: 'profile',
              Dashboard: 'dashboard',
              Notifications: 'notifications',
              NotificationCreate: 'notifications/create',
              NotificationDetail: 'notifications/:id',
              Resources: 'resources',
              ResourceCreate: 'resources/create',
              ResourceDetail: 'resources/:id',
              Settings: 'settings',
              SettingDetail: 'settings/:id',
              Users: 'users',
              UserDetail: 'users/:id',
              Campaigns: 'campaigns',
              CampaignDetail: 'campaigns/:id',
              Roles: 'roles',
              RoleDetail: 'roles/:id',
              Gallery: 'gallery',
              GalleryCreate: 'gallery/create',
              GalleryDetail: 'gallery/:id',
              Feed: 'feed',
              FeedCreate: 'feed/create',
              FeedDetail: 'feed/:id',
            },
          },
        },
      },
    },
  },
} as any;

export const Nav: React.FC = () => {
  const dispatch = useAppDispatch();
  const { currentUser, loading } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.has('logout')) {
      window.close();
    } else {
      dispatch(authActions.refreshSession());
    }
  }, []);

  const innerNav = useMemo(() => {
    if (currentUser) {
      const role = currentUser?.role?.name;
      if (role && [RoleNames.ADMIN].includes(role as RoleNames)) {
        return <AppNavigation />;
      }
      return <UnauthorizedNavigation />;
    }

    return <AuthNavigation />;
  }, [currentUser]);

  if (loading) {
    return <SplashScreen />;
  }

  return <NavigationContainer linking={linking}>{innerNav}</NavigationContainer>;
};
