import { Analytics } from '../models/data';
import BaseService from './BaseService';

export class AnalyticsService extends BaseService {
  async getAnalytics() {
    return (await this.getJson<Analytics>('/analytics')).data;
  }
}

export default new AnalyticsService();
