import { Campaign } from '../models/data';
import BaseService from './BaseService';

export class CampaignService extends BaseService {
  async getCampaigns(query?: string) {
    let params = {};

    if (query) {
      params['query'] = query;
    }

    return (
      await this.getJson<Campaign[]>('/campaigns', {
        params,
      })
    ).data;
  }

  async getCampaign(id: string) {
    return (await this.getJson<Campaign>('/campaigns/' + id)).data;
  }

  async update(campaign: Campaign) {
    return (await this.putJson<Campaign>('/campaigns/' + campaign.sfid, campaign)).data;
  }
}

export default new CampaignService();
