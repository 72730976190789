import { Box, Button, Input, ScrollView, VStack } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import { SettingsListItem } from '../../components/settings';
import { useAppDispatch, useAppSelector } from '../../store';
import { settingsActions } from '../../store/actions';
import { SplashScreen } from '../SplashScreen';

export const SettingsScreen: React.FC<any> = ({ navigation }) => {
  const dispatch = useAppDispatch();

  const settingState = useAppSelector((state) => state.settings);
  const { loading: settingsLoading, settings } = settingState;

  const [initialLoading, setInitialLoading] = useState(true);
  const [currentQuery, setCurrentQuery] = useState('');

  useEffect(() => {
    const loadData = async () => {
      await dispatch(settingsActions.getSettings(undefined)).unwrap();
      setInitialLoading(false);
    };

    loadData();
  }, []);

  const search = useCallback(() => {
    dispatch(settingsActions.getSettings(currentQuery));
  }, [currentQuery]);

  if (initialLoading) {
    return <SplashScreen />;
  }

  return (
    <ScrollView>
      <Box p={4}>
        <VStack mb={4}>
          <Input
            placeholder="Search for settings..."
            onChangeText={setCurrentQuery}
            autoCapitalize="none"
            returnKeyType="default"
            value={currentQuery}
            isDisabled={settingsLoading}
            onSubmitEditing={search}
            InputRightElement={
              <Button
                onPress={search}
                colorScheme="secondary"
                isDisabled={!currentQuery || settingsLoading}
                isLoading={settingsLoading}
              >
                SEARCH
              </Button>
            }
          />
        </VStack>

        <VStack space={4}>
          {settings.map((setting) => (
            <SettingsListItem key={setting.id} setting={setting} />
          ))}
        </VStack>
      </Box>
    </ScrollView>
  );
};
