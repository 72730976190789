import { User } from '../../models/data';

export enum AuthStatus {
  IDLE = 'idle',
  AUTHENTICATING = 'authenticating',
  AUTHENTICATED = 'authenticated',
  UNAUTHENTICATING = 'unauthenticating',
  UNAUTHENTICATED = 'unauthenticated',
}

export interface AuthState {
  status: AuthStatus;
  currentUser: User;
  token: string;
  loading: boolean;
  updateLoading: boolean;
  error: string;
}
