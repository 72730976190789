import { useFormik } from 'formik';
import { Box, Button, Divider, FormControl, Heading, Input, ScrollView, VStack } from 'native-base';
import React, { useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { useDialog } from '../contexts';
import { useAppDispatch, useAppSelector } from '../store';
import { authActions } from '../store/actions';
import { SplashScreen } from './SplashScreen';

const userProfileSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
});

export const UserProfileScreen: React.FC<any> = ({ navigation }) => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector((state) => state.auth);
  const { showAlert } = useDialog();
  const { currentUser, updateLoading, error } = authState;

  const formik = useFormik({
    initialValues: {
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
    },
    validationSchema: userProfileSchema,
    onSubmit: (values) => {
      submitUpdate(values.firstName, values.lastName);
    },
  });

  useEffect(() => {
    if (error) {
      dispatch(authActions.updateError(null));
      showAlert({ title: 'Update failed', message: 'Please try again.' });
    }
  }, [error, showAlert]);

  const submitUpdate = useCallback(
    async (firstName: string, lastName: string) => {
      await dispatch(authActions.updateCurrentUser({ ...currentUser, firstName, lastName })).unwrap();
      showAlert({ title: 'Update Successful!', message: 'Your profile has been updated.' });
    },
    [showAlert],
  );

  if (!currentUser) {
    return <SplashScreen />;
  }

  return (
    <ScrollView>
      <Box p={4}>
        <Heading>Update Your User Profile</Heading>
        <VStack style={{ marginTop: 20, marginBottom: 20 }}>
          <FormControl isInvalid={formik.touched.firstName && formik.errors.firstName ? true : false}>
            <FormControl.Label>First Name</FormControl.Label>
            <FormControl.ErrorMessage>
              {formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
            </FormControl.ErrorMessage>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('firstName')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.firstName}
              isDisabled={updateLoading}
            />
          </FormControl>
          <FormControl isInvalid={formik.touched.lastName && formik.errors.lastName ? true : false}>
            <FormControl.Label>Last Name</FormControl.Label>
            <FormControl.ErrorMessage>
              {formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null}
            </FormControl.ErrorMessage>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('lastName')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.lastName}
              isDisabled={updateLoading}
            />
          </FormControl>
        </VStack>

        <Divider my={4} />

        <Button.Group space={4} justifyContent="flex-end">
          <Button onPress={() => formik.handleSubmit()} isDisabled={updateLoading} isLoading={updateLoading}>
            SAVE
          </Button>
        </Button.Group>
      </Box>
    </ScrollView>
  );
};
