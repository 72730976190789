import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Analytics } from '../../models/data';
import { analyticsService } from '../../services';
import { AnalyticsState } from './types';

const getAnalytics = createAsyncThunk('analytics/getAnalytics', async () => {
  return analyticsService.getAnalytics();
});

const initialState: AnalyticsState = {
  currentAnalytics: null,
  loading: false,
  error: null,
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setAnalytics(state, { payload: { analytics } }: PayloadAction<{ analytics: Analytics }>) {
      state.currentAnalytics = analytics;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnalytics.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.currentAnalytics = action.payload;
      })
      .addCase(getAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load analytics.';
      });
  },
});

export const analyticsActions = { ...analyticsSlice.actions, getAnalytics };

export type AnalyticsSlice = {
  [analyticsSlice.name]: ReturnType<typeof analyticsSlice['reducer']>;
};
