import { Button } from 'native-base';
import React, { useCallback } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { useSessionStart } from '../hooks';
import { useAppDispatch, useAppSelector } from '../store';
import { authActions } from '../store/actions';
import { colors } from '../styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    width: '100%',
  },
  h1: {
    color: colors.primary,
    fontSize: 40,
  },
  h2: {
    color: colors.primary,
    fontSize: 18,
    marginTop: 8,
    fontWeight: 'bold',
  },
  topContainer: {
    flex: 4,
    display: 'flex',
    width: '90vw',
  },
  middleContainer: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  bottomContainer: {
    justifyContent: 'flex-end',
    width: '90%',
    margin: 20,
    padding: 10,
  },
  copyContainer: {
    alignItems: 'center',
    width: '100%',
    padding: 8,
    margin: 8,
  },
  image: {
    flex: 1,
    resizeMode: 'contain',
  },
  buttonContainer: {
    backgroundColor: '#044959',
    borderRadius: 5,
    padding: 8,
    margin: 8,
  },
});

export const LoginScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.auth);

  const { dataLoading } = useSessionStart({
    onStartSession: useCallback(() => {
      console.log('Session started');
    }, []),
    onInvalidRole: useCallback(() => {
      console.log('Invalid role');
    }, []),
    onNoSession: useCallback(() => {
      console.log('No session');
    }, []),
  });

  const handleLogin = useCallback(async () => {
    dispatch(authActions.signInUser());
  }, [dispatch]);

  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <Image source={require('../../assets/images/RTSWS-Logo_-tag-line_-NAVY-2021.jpeg')} style={styles.image} />
      </View>
      <View style={styles.middleContainer}>
        <View style={styles.copyContainer}>
          <Text style={styles.h2}>SYSTEM ADMINISTRATION</Text>
        </View>
      </View>
      <View style={styles.bottomContainer}>
        <View style={styles.copyContainer}>
          <Text style={styles.h2}></Text>
        </View>
        <View style={styles.buttonContainer}>
          <Button
            isDisabled={loading || dataLoading}
            isLoading={loading || dataLoading}
            onPress={handleLogin}
            color="#044959"
          >
            LOGIN
          </Button>
        </View>
      </View>
    </View>
  );
};
