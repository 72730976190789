import { Feather } from '@expo/vector-icons';
import moment from 'moment-timezone';
import { Box, Button, Center, Divider, Heading, HStack, Link, ScrollView, Text, View, VStack } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { NotificationStatus } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../store';
import { notificationsActions } from '../../store/actions';
import { colors, rootStyles } from '../../styles';
import { SplashScreen } from '../SplashScreen';

export const NotificationsScreen: React.FC<any> = ({ navigation, route }) => {
  const dispatch = useAppDispatch();
  const { status } = route.params ?? {};

  const notificationState = useAppSelector((state) => state.notifications);
  const { loading: notificationsLoading, notifications, currentPage, pageCount } = notificationState;

  const [initialLoad, setInitialLoad] = useState(true);
  const [currentStatus, setCurrentStatus] = useState(status ?? NotificationStatus.STATUS_SCHEDULED);

  useEffect(() => {
    const loadData = async () => {
      console.log('status', status);
      if (!status) {
        setTimeout(() => {
          navigation.setParams({
            status: currentStatus,
          });
        });
      }

      await dispatch(notificationsActions.getNotifications({ page: 1, status: currentStatus })).unwrap();
      setInitialLoad(false);
    };

    loadData();
  }, []);

  const incrementPage = useCallback(() => {
    dispatch(notificationsActions.getNotifications({ page: currentPage + 1, status: currentStatus }));
  }, [currentPage, currentStatus]);

  const decrementPage = useCallback(() => {
    if (currentPage > 1) {
      dispatch(notificationsActions.getNotifications({ page: currentPage - 1, status: currentStatus }));
    }
  }, [currentPage, currentStatus]);

  const changeStatus = useCallback((status: NotificationStatus) => {
    setCurrentStatus(status);
    navigation.setParams({
      status,
    });
    dispatch(notificationsActions.getNotifications({ page: 1, status }));
  }, []);

  if (initialLoad) {
    return <SplashScreen />;
  }

  return (
    <ScrollView>
      <VStack p={4}>
        <Button.Group space={2}>
          <Link href="/notifications/create">
            <Button mb={4} isDisabled={notificationsLoading}>
              Create New Notification
            </Button>
          </Link>
        </Button.Group>

        <Button.Group mb={4} size="sm" width="100%" isAttached>
          <Button
            variant={currentStatus === NotificationStatus.STATUS_SCHEDULED ? 'outline' : 'solid'}
            onPress={() => changeStatus(NotificationStatus.STATUS_SCHEDULED)}
            isDisabled={notificationsLoading}
          >
            Scheduled Notifications
          </Button>
          <Button
            variant={currentStatus === NotificationStatus.STATUS_SENT ? 'outline' : 'solid'}
            onPress={() => changeStatus(NotificationStatus.STATUS_SENT)}
            isDisabled={notificationsLoading}
          >
            Sent Notifications
          </Button>
          <Button
            variant={currentStatus === NotificationStatus.STATUS_FAILED ? 'outline' : 'solid'}
            onPress={() => changeStatus(NotificationStatus.STATUS_FAILED)}
            isDisabled={notificationsLoading}
          >
            Failed Notifications
          </Button>
        </Button.Group>

        <View mb={4}>
          <Text justifyContent="flex-end" width="100%">
            All times Central (America/Chicago)
          </Text>
        </View>

        {notificationsLoading && (
          <Center>
            <ActivityIndicator color="#bbbbbb" />
          </Center>
        )}

        {!notificationsLoading && (
          <Box>
            <VStack space={4} mb={4}>
              {notifications.map((notification) => {
                return (
                  <Link href={`notifications/${notification.id}`} key={notification.id} width="100%">
                    <VStack width="100%" space={4}>
                      <HStack alignItems="center" justifyContent="space-between">
                        <Box>
                          <Text>
                            <Heading size="xs">
                              {notification.status === NotificationStatus.STATUS_SCHEDULED ? 'SENDING: ' : 'SENT: '}
                            </Heading>
                            {moment(notification.sendAt).tz('America/Chicago').format('MMM D, YYYY, h:mm a')}
                          </Text>
                          <Text>
                            <Heading size="xs">SUBJECT:</Heading> {notification.subject}
                          </Text>
                          <Text>
                            <Heading size="xs">MESSAGE:</Heading> {notification.message}
                          </Text>
                        </Box>
                        <Box>
                          <Feather name="chevron-right" size={25} style={{ color: colors.primary }} />
                        </Box>
                      </HStack>
                      <Divider />
                    </VStack>
                  </Link>
                );
              })}
              {notifications.length === 0 && (
                <Text style={rootStyles.centeredText} textTransform="uppercase" m={8}>
                  No notifications available.
                </Text>
              )}
            </VStack>

            <HStack justifyContent="space-between" space={2}>
              <Button
                flex={1}
                justifyContent="flex-start"
                onPress={() => decrementPage()}
                variant="ghost"
                disabled={currentPage <= 1}
                leftIcon={
                  <Feather
                    name="chevron-left"
                    size={20}
                    style={{ color: currentPage <= 1 ? colors.lightGrey : colors.primary }}
                  />
                }
              >
                Previous
              </Button>
              <Button
                flex={1}
                justifyContent="flex-end"
                onPress={() => incrementPage()}
                variant="ghost"
                disabled={currentPage >= pageCount}
                rightIcon={
                  <Feather
                    name="chevron-right"
                    size={20}
                    style={{ color: currentPage >= pageCount ? colors.lightGrey : colors.primary }}
                  />
                }
              >
                Next
              </Button>
            </HStack>
          </Box>
        )}
      </VStack>
    </ScrollView>
  );
};
