import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Campaign } from '../../models/data';
import { campaignService } from '../../services';
import { CampaignState } from './types';

export const getCampaigns = createAsyncThunk('campaigns/getCampaigns', async (query?: string) => {
  return campaignService.getCampaigns(query || null);
});

export const getCampaign = createAsyncThunk('campaigns/getCampaign', async (id: string) => {
  return campaignService.getCampaign(id);
});

export const updateCampaign = createAsyncThunk('campaigns/updateCampaign', async (campaign: Campaign) => {
  return campaignService.update(campaign);
});

const initialState: CampaignState = {
  currentCampaign: null,
  campaigns: [],
  loading: false,
  error: null,
  currentPage: 1,
  pageCount: null,
};

export const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setCampaigns(state, { payload }: PayloadAction<Campaign[]>) {
      state.campaigns = payload;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaigns.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCampaigns.fulfilled, (state, action) => {
        state.loading = false;
        state.campaigns = action.payload;
      })
      .addCase(getCampaigns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load campaigns.';
      });

    builder
      .addCase(getCampaign.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        state.loading = false;
        state.currentCampaign = action.payload;
      })
      .addCase(getCampaign.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load campaign.';
      });

    builder
      .addCase(updateCampaign.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        state.loading = false;
        state.currentCampaign = action.payload;
      })
      .addCase(updateCampaign.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to update campaign.';
      });
  },
});

export const campaignsActions = { ...campaignsSlice.actions, getCampaigns, getCampaign, updateCampaign };

export type CampaignsSlice = {
  [campaignsSlice.name]: ReturnType<typeof campaignsSlice['reducer']>;
};
