import { Setting } from '../models/data';
import BaseService from './BaseService';

export class SettingService extends BaseService {
  async getSettings(query?: string) {
    let params = {};

    if (query) {
      params['query'] = query;
    }

    return (
      await this.getJson<Setting[]>('/settings', {
        params,
      })
    ).data;
  }

  async getSetting(id: string) {
    return (await this.getJson<Setting>('/settings/' + id)).data;
  }

  async update(setting: Setting) {
    return (await this.putJson<Setting>('/settings/' + setting.id, setting)).data;
  }
}

export default new SettingService();
