import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { analyticsSlice } from './analytics/slice';
import { authSlice } from './auth/slice';
import { campaignsSlice } from './campaign/slice';
import monitorReducerEnhancer from './enhancers/monitorReducer';
import { feedSlice } from './feed/slice';
import { gallerySlice } from './gallery/slice';
import appMiddleware from './middleware';
import { notificationsSlice } from './notification/slice';
import { resourcesSlice } from './resource/slice';
import { rolesSlice } from './role/slice';
import { settingsSlice } from './setting/slice';
import { usersSlice } from './user/slice';

const store = configureStore({
  reducer: {
    [analyticsSlice.name]: analyticsSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [campaignsSlice.name]: campaignsSlice.reducer,
    [notificationsSlice.name]: notificationsSlice.reducer,
    [resourcesSlice.name]: resourcesSlice.reducer,
    [rolesSlice.name]: rolesSlice.reducer,
    [settingsSlice.name]: settingsSlice.reducer,
    [usersSlice.name]: usersSlice.reducer,
    [gallerySlice.name]: gallerySlice.reducer,
    [feedSlice.name]: feedSlice.reducer,
  },
  enhancers: [monitorReducerEnhancer],
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware();

    if (process.env.NODE_ENV === 'development') {
      middleware.push(appMiddleware.logger);
    }

    return middleware;
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
