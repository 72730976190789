import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../models/data';
import { authService, userService } from '../../services';
import { AuthState, AuthStatus } from './types';

const initialState: AuthState = {
  status: AuthStatus.IDLE,
  currentUser: null,
  token: null,
  loading: true,
  updateLoading: false,
  error: null,
};

export const refreshSession = createAsyncThunk('auth/refreshSession', async (_, thunkAPI) => {
  const sessionRefreshed = await authService.refreshSession();

  if (sessionRefreshed) {
    await thunkAPI.dispatch(fetchUser());
  } else {
    await thunkAPI.dispatch(notAuthenticated());
  }
});

const notAuthenticated = createAsyncThunk('auth/notAuthenticated', async () => {
  // Handle any thing that should happen when a user loads the app without authentication
});

export const fetchUser = createAsyncThunk('auth/refreshCurrentUser', async () => {
  return userService.getSelf();
});

export const signInUser = createAsyncThunk('auth/signInUser', async (_, thunkAPI) => {
  const signedIn = await authService.signIn();

  if (signedIn) {
    await thunkAPI.dispatch(fetchUser());
  } else {
    await thunkAPI.dispatch(notAuthenticated());
  }
});

export const updateCurrentUser = createAsyncThunk('auth/updateCurrentUser', async (user: User) => {
  return userService.updateSelf(user);
});

export const signOutUser = createAsyncThunk(
  'auth/signOutUser',
  async (includeBrowserSessionLogout: boolean = true, thunkAPI) => {
    return await authService.signOut(includeBrowserSessionLogout);
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setUser(state, { payload: { newUser } }: PayloadAction<{ newUser: User }>) {
      state.currentUser = newUser;
    },

    setToken(state, { payload: { token } }: PayloadAction<{ token: string }>) {
      state.token = token;
    },

    updateError(state, { payload }: PayloadAction<string>) {
      state.error = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshSession.pending, (state) => {
      state.status = AuthStatus.AUTHENTICATING;
      state.loading = true;
    });

    builder.addCase(refreshSession.rejected, (state, { error }) => {
      console.log('refreshSession.rejected', error);
    });

    builder.addCase(notAuthenticated.fulfilled, (state) => {
      state.loading = false;
      state.status = AuthStatus.UNAUTHENTICATED;
    });

    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
        state.status = AuthStatus.AUTHENTICATED;
        console.log('AuthSlice: Fetched user', state.currentUser);
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to set current user.';
        state.currentUser = null;
        console.log('AuthSlice: Could not fetch user:', action);
      });

    builder
      .addCase(updateCurrentUser.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateCurrentUser.fulfilled, (state, action) => {
        state.updateLoading = false;
        state.currentUser = action.payload;
        state.status = AuthStatus.AUTHENTICATED;
      })
      .addCase(updateCurrentUser.rejected, (state, action) => {
        state.updateLoading = false;
        state.error = action.error.message ?? 'Unable to update current user.';
      });

    builder
      .addCase(signInUser.pending, (state) => {
        state.loading = true;
        state.status = AuthStatus.AUTHENTICATING;
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.loading = false;
        state.status = AuthStatus.UNAUTHENTICATED;
        state.error = action.error.message ?? 'Unable to sign in user.';
      });

    builder
      .addCase(signOutUser.pending, (state) => {
        state.loading = true;
        state.status = AuthStatus.UNAUTHENTICATING;
      })
      .addCase(signOutUser.fulfilled, (state) => {
        state.loading = false;
        state.currentUser = null;
        state.status = AuthStatus.UNAUTHENTICATED;

        // TODO: finish sign out
        //       dispatch(clearCache());

        // This code is intended to force the app to reload when the user signs out.
        // However, it does not currently function as expected. Since Sign Out only
        // happens from the MenuScreen, there is code there to navigate after the
        // sign out is complete.

        // if (Platform.OS === 'web') {
        //   window.location.reload();
        // } else {
        //   const reload = async () => {
        //     try {
        //       console.log('AuthSlice: Reloading app...');
        //       await Updates.reloadAsync();
        //       console.log('AuthSlice: Reloaded...');
        //     } catch (err) {
        //       console.log('AuthSlice: Error reloading app', err);
        //     }
        //   };

        //   reload();
        // }
      })
      .addCase(signOutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to sign out user.';
      });
  },
});

export const authActions = {
  ...authSlice.actions,
  refreshSession,
  signInUser,
  fetchUser,
  updateCurrentUser,
  signOutUser,
};

export type AuthSlice = {
  [authSlice.name]: ReturnType<typeof authSlice['reducer']>;
};
