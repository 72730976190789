import { Feather } from '@expo/vector-icons';
import { useFormik } from 'formik';
import { Box, Button, Divider, FormControl, Heading, HStack, Input, Link, ScrollView, Text, VStack } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../store';
import { campaignsActions } from '../../store/actions';
import { colors } from '../../styles';

const searchSchema = Yup.object().shape({
  search: Yup.string().min(3),
});

export const CampaignsScreen: React.FC<any> = ({ navigation }) => {
  const dispatch = useAppDispatch();

  const campaignState = useAppSelector((state) => state.campaigns);
  const { loading: campaignsLoading, campaigns } = campaignState;

  const [currentQuery, setCurrentQuery] = useState(null);

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    validationSchema: searchSchema,
    onSubmit: (values) => {
      search(values.search);
    },
  });

  useEffect(() => {
    if (currentQuery) {
      dispatch(campaignsActions.getCampaigns(currentQuery));
    }
  }, [currentQuery]);

  const search = useCallback((query: string) => {
    setCurrentQuery(query);
  }, []);

  return (
    <ScrollView>
      <Box p={4}>
        <VStack space={4}>
          <FormControl isInvalid={formik.touched.search && formik.errors.search ? true : false}>
            <FormControl.ErrorMessage>
              {formik.touched.search && formik.errors.search ? formik.errors.search : null}
            </FormControl.ErrorMessage>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('search')}
              autoCapitalize="none"
              returnKeyType="default"
              value={formik.values.search}
              isDisabled={campaignsLoading}
              InputRightElement={
                <Button
                  onPress={() => formik.handleSubmit()}
                  colorScheme="secondary"
                  isLoading={campaignsLoading}
                  isDisabled={campaignsLoading}
                >
                  SEARCH
                </Button>
              }
            />
          </FormControl>

          {campaigns.length > 0 ? (
            <VStack space={4}>
              {campaigns.map((campaign) => {
                return (
                  <Link href={`campaigns/${campaign.sfid}`} key={campaign.sfid} width="100%">
                    <VStack width="100%" space={4}>
                      <HStack alignItems="center" justifyContent="space-between" width="100%">
                        <Text style={{ color: colors.secondary }}>
                          {campaign.name} (Salesforce ID: {campaign.sfid})
                        </Text>
                        <Feather name="chevron-right" size={25} style={{ color: colors.primary }} />
                      </HStack>
                      <Divider />
                    </VStack>
                  </Link>
                );
              })}
            </VStack>
          ) : (
            <Heading style={{ alignSelf: 'center', marginTop: '20px' }}>
              {currentQuery ? 'No matches found.' : 'Enter search query to find a campaign.'}
            </Heading>
          )}
        </VStack>
      </Box>
    </ScrollView>
  );
};
