import React, { useCallback } from 'react';
import { FeedForm } from '../../components/feed';

export const FeedCreateScreen: React.FC<any> = (props) => {
  const onSubmit = useCallback(() => {
    window.location.href = '/feed';
  }, []);

  return <FeedForm onSubmit={onSubmit} />;
};
