import React, { useCallback, useEffect, useState } from 'react';
import { ResourcesForm } from '../../components/resources';
import { useDialog } from '../../contexts';
import { useAppDispatch, useAppSelector } from '../../store';
import { resourcesActions } from '../../store/actions';
import { SplashScreen } from '../SplashScreen';

export const ResourceDetailScreen: React.FC<any> = ({ navigation, route }) => {
  const dispatch = useAppDispatch();
  const { id } = route.params;

  const { showAlert, showConfirm } = useDialog();

  const resourceState = useAppSelector((state) => state.resources);
  const { currentResource } = resourceState;

  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      await dispatch(resourcesActions.getResource(id)).unwrap();
      setInitialLoading(false);
    };

    loadData();
  }, []);

  const onSubmit = useCallback(async () => {
    await showAlert({ title: 'Success', message: 'Resource has been updated.' });
  }, []);

  const onDelete = useCallback(async () => {
    const confirmation = await showConfirm({ title: 'Delete Resource', message: 'Are you sure?' });

    if (confirmation) {
      await dispatch(resourcesActions.deleteResource(currentResource)).unwrap();
      window.location.href = '/resources';
    }
  }, [currentResource]);

  if (initialLoading) {
    return <SplashScreen />;
  }

  return <ResourcesForm resource={currentResource} onSubmit={onSubmit} onDelete={onDelete} />;
};
