import { Feather } from '@expo/vector-icons';
import { Box, Divider, HStack, Link, Text, VStack } from 'native-base';
import React, { memo } from 'react';
import { Setting } from '../../models/data';
import { colors } from '../../styles';

export interface SettingListItemProps {
  setting: Setting;
}

export const SettingsListItem: React.FC<SettingListItemProps> = memo(({ setting }) => {
  return (
    <Link href={`settings/${setting.id}`} width="100%">
      <VStack width="100%" space={4}>
        <HStack alignItems="center" justifyContent="space-between" width="100%">
          <Box>
            <Text>{setting.name}</Text>
            <Text color={colors.primary} fontWeight="bold" fontFamily="mono">
              {setting.value || '(Not Set)'}
            </Text>
          </Box>
          <Box>
            <Feather name="chevron-right" size={25} style={{ color: colors.primary }} />
          </Box>
        </HStack>
        <Divider />
      </VStack>
    </Link>
  );
});
