import React from 'react';
import { ActivityIndicator, SafeAreaView, StatusBar } from 'react-native';

export const SplashScreen: React.FC<any> = () => {
  return (
    <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}>
      <StatusBar barStyle="default" />
      {/* <Text style={styles.loadingText}>ROCK THE STREET, WALL STREET</Text> */}
      <ActivityIndicator color="#bbbbbb" />
    </SafeAreaView>
  );
};
