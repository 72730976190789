export const MATERIAL_ICON_VALUES = [
  'add-alarm',
  'add-alert',
  'add-box',
  'add-business',
  'add-call',
  'add-chart',
  'add-circle',
  'add-circle-outline',
  'add-comment',
  'add-ic-call',
  'add-link',
  'add-location',
  'add-location-alt',
  'add-moderator',
  'add-photo-alternate',
  'add-road',
  'add-shopping-cart',
  'add-task',
  'add-to-drive',
  'add-to-home-screen',
  'add-to-photos',
  'add-to-queue',
  'addchart',
  'adjust',
  'admin-panel-settings',
  'agriculture',
  'airline-seat-flat',
  'airline-seat-flat-angled',
  'airline-seat-individual-suite',
  'airline-seat-legroom-extra',
  'airline-seat-legroom-normal',
  'airline-seat-legroom-reduced',
  'airline-seat-recline-extra',
  'airline-seat-recline-normal',
  'airplanemode-active',
  'airplanemode-inactive',
  'airplanemode-off',
  'airplanemode-on',
  'airplay',
  'airport-shuttle',
  'alarm',
  'alarm-add',
  'alarm-off',
  'alarm-on',
  'album',
  'align-horizontal-center',
  'align-horizontal-left',
  'align-horizontal-right',
  'align-vertical-bottom',
  'align-vertical-center',
  'align-vertical-top',
  'all-inbox',
  'all-inclusive',
  'all-out',
  'alt-route',
  'alternate-email',
  'amp-stories',
  'analytics',
  'anchor',
  'android',
  'animation',
  'announcement',
  'apartment',
  'api',
  'app-blocking',
  'app-registration',
  'app-settings-alt',
  'approval',
  'apps',
  'architecture',
  'archive',
  'arrow-back',
  'arrow-back-ios',
  'arrow-circle-down',
  'arrow-circle-up',
  'arrow-downward',
  'arrow-drop-down',
  'arrow-drop-down-circle',
  'arrow-drop-up',
  'arrow-forward',
  'arrow-forward-ios',
  'arrow-left',
  'arrow-right',
  'arrow-right-alt',
  'arrow-upward',
  'art-track',
  'article',
  'aspect-ratio',
  'assessment',
  'assignment',
  'assignment-ind',
  'assignment-late',
  'assignment-return',
  'assignment-returned',
  'assignment-turned-in',
  'assistant',
  'assistant-direction',
  'assistant-navigation',
  'assistant-photo',
  'atm',
  'attach-email',
  'attach-file',
  'attach-money',
  'attachment',
  'attractions',
  'audiotrack',
  'auto-awesome',
  'auto-awesome-mosaic',
  'auto-awesome-motion',
  'auto-delete',
  'auto-fix-high',
  'auto-fix-normal',
  'auto-fix-off',
  'auto-stories',
  'autorenew',
  'av-timer',
  'baby-changing-station',
  'backpack',
  'backspace',
  'backup',
  'backup-table',
  'badge',
  'bakery-dining',
  'ballot',
  'bar-chart',
  'batch-prediction',
  'bathtub',
  'battery-alert',
  'battery-charging-full',
  'battery-full',
  'battery-std',
  'battery-unknown',
  'beach-access',
  'bedtime',
  'beenhere',
  'bento',
  'bike-scooter',
  'biotech',
  'block',
  'block-flipped',
  'bluetooth',
  'bluetooth-audio',
  'bluetooth-connected',
  'bluetooth-disabled',
  'bluetooth-searching',
  'blur-circular',
  'blur-linear',
  'blur-off',
  'blur-on',
  'bolt',
  'book',
  'book-online',
  'bookmark',
  'bookmark-border',
  'bookmark-outline',
  'bookmarks',
  'border-all',
  'border-bottom',
  'border-clear',
  'border-color',
  'border-horizontal',
  'border-inner',
  'border-left',
  'border-outer',
  'border-right',
  'border-style',
  'border-top',
  'border-vertical',
  'branding-watermark',
  'breakfast-dining',
  'brightness-1',
  'brightness-2',
  'brightness-3',
  'brightness-4',
  'brightness-5',
  'brightness-6',
  'brightness-7',
  'brightness-auto',
  'brightness-high',
  'brightness-low',
  'brightness-medium',
  'broken-image',
  'browser-not-supported',
  'brunch-dining',
  'brush',
  'bubble-chart',
  'bug-report',
  'build',
  'build-circle',
  'burst-mode',
  'bus-alert',
  'business',
  'business-center',
  'cached',
  'cake',
  'calculate',
  'calendar-today',
  'calendar-view-day',
  'call',
  'call-end',
  'call-made',
  'call-merge',
  'call-missed',
  'call-missed-outgoing',
  'call-received',
  'call-split',
  'call-to-action',
  'camera',
  'camera-alt',
  'camera-enhance',
  'camera-front',
  'camera-rear',
  'camera-roll',
  'campaign',
  'cancel',
  'cancel-presentation',
  'cancel-schedule-send',
  'car-rental',
  'car-repair',
  'card-giftcard',
  'card-membership',
  'card-travel',
  'carpenter',
  'cases',
  'casino',
  'cast',
  'cast-connected',
  'cast-for-education',
  'category',
  'celebration',
  'cell-wifi',
  'center-focus-strong',
  'center-focus-weak',
  'change-history',
  'charging-station',
  'chat',
  'chat-bubble',
  'chat-bubble-outline',
  'check',
  'check-box',
  'check-box-outline-blank',
  'check-circle',
  'check-circle-outline',
  'checkroom',
  'chevron-left',
  'chevron-right',
  'child-care',
  'child-friendly',
  'chrome-reader-mode',
  'circle',
  'circle-notifications',
  'class',
  'clean-hands',
  'cleaning-services',
  'clear',
  'clear-all',
  'close',
  'close-fullscreen',
  'closed-caption',
  'closed-caption-disabled',
  'closed-caption-off',
  'cloud',
  'cloud-circle',
  'cloud-done',
  'cloud-download',
  'cloud-off',
  'cloud-queue',
  'cloud-upload',
  'code',
  'collections',
  'collections-bookmark',
  'color-lens',
  'colorize',
  'comment',
  'comment-bank',
  'commute',
  'compare',
  'compare-arrows',
  'compass-calibration',
  'compress',
  'computer',
  'confirmation-num',
  'confirmation-number',
  'connect-without-contact',
  'connected-tv',
  'construction',
  'contact-mail',
  'contact-page',
  'contact-phone',
  'contact-support',
  'contactless',
  'contacts',
  'content-copy',
  'content-cut',
  'content-paste',
  'control-camera',
  'control-point',
  'control-point-duplicate',
  'copyright',
  'coronavirus',
  'corporate-fare',
  'countertops',
  'create',
  'create-new-folder',
  'credit-card',
  'crop',
  'crop-16-9',
  'crop-3-2',
  'crop-5-4',
  'crop-7-5',
  'crop-din',
  'crop-free',
  'crop-landscape',
  'crop-original',
  'crop-portrait',
  'crop-rotate',
  'crop-square',
  'dangerous',
  'dashboard',
  'dashboard-customize',
  'data-usage',
  'date-range',
  'deck',
  'dehaze',
  'delete',
  'delete-forever',
  'delete-outline',
  'delete-sweep',
  'delivery-dining',
  'departure-board',
  'description',
  'design-services',
  'desktop-access-disabled',
  'desktop-mac',
  'desktop-windows',
  'details',
  'developer-board',
  'developer-mode',
  'device-hub',
  'device-thermostat',
  'device-unknown',
  'devices',
  'devices-other',
  'dialer-sip',
  'dialpad',
  'dinner-dining',
  'directions',
  'directions-bike',
  'directions-boat',
  'directions-bus',
  'directions-car',
  'directions-ferry',
  'directions-off',
  'directions-railway',
  'directions-run',
  'directions-subway',
  'directions-train',
  'directions-transit',
  'directions-walk',
  'dirty-lens',
  'disabled-by-default',
  'disc-full',
  'dnd-forwardslash',
  'dns',
  'do-not-disturb',
  'do-not-disturb-alt',
  'do-not-disturb-off',
  'do-not-disturb-on',
  'do-not-step',
  'do-not-touch',
  'dock',
  'domain',
  'domain-disabled',
  'domain-verification',
  'done',
  'done-all',
  'done-outline',
  'donut-large',
  'donut-small',
  'double-arrow',
  'drafts',
  'drag-handle',
  'drag-indicator',
  'drive-eta',
  'drive-file-move',
  'drive-file-move-outline',
  'drive-file-rename-outline',
  'drive-folder-upload',
  'dry',
  'dry-cleaning',
  'duo',
  'dvr',
  'dynamic-feed',
  'dynamic-form',
  'east',
  'eco',
  'edit',
  'edit-attributes',
  'edit-location',
  'edit-off',
  'edit-road',
  'eject',
  'elderly',
  'electric-bike',
  'electric-car',
  'electric-moped',
  'electric-rickshaw',
  'electric-scooter',
  'electrical-services',
  'elevator',
  'email',
  'emoji-emotions',
  'emoji-events',
  'emoji-flags',
  'emoji-food-beverage',
  'emoji-nature',
  'emoji-objects',
  'emoji-people',
  'emoji-symbols',
  'emoji-transportation',
  'engineering',
  'enhance-photo-translate',
  'enhanced-encryption',
  'equalizer',
  'error',
  'error-outline',
  'escalator',
  'escalator-warning',
  'euro',
  'euro-symbol',
  'ev-station',
  'event',
  'event-available',
  'event-busy',
  'event-note',
  'event-seat',
  'exit-to-app',
  'expand',
  'expand-less',
  'expand-more',
  'explicit',
  'explore',
  'explore-off',
  'exposure',
  'exposure-minus-1',
  'exposure-minus-2',
  'exposure-neg-1',
  'exposure-neg-2',
  'exposure-plus-1',
  'exposure-plus-2',
  'exposure-zero',
  'extension',
  'face',
  'face-retouching-natural',
  'facebook',
  'fact-check',
  'family-restroom',
  'fast-forward',
  'fast-rewind',
  'fastfood',
  'favorite',
  'favorite-border',
  'favorite-outline',
  'featured-play-list',
  'featured-video',
  'feedback',
  'fence',
  'festival',
  'fiber-dvr',
  'fiber-manual-record',
  'fiber-new',
  'fiber-pin',
  'fiber-smart-record',
  'file-copy',
  'file-download',
  'file-download-done',
  'file-present',
  'file-upload',
  'filter',
  'filter-1',
  'filter-2',
  'filter-3',
  'filter-4',
  'filter-5',
  'filter-6',
  'filter-7',
  'filter-8',
  'filter-9',
  'filter-9-plus',
  'filter-alt',
  'filter-b-and-w',
  'filter-center-focus',
  'filter-drama',
  'filter-frames',
  'filter-hdr',
  'filter-list',
  'filter-list-alt',
  'filter-none',
  'filter-tilt-shift',
  'filter-vintage',
  'find-in-page',
  'find-replace',
  'fingerprint',
  'fire-extinguisher',
  'fire-hydrant',
  'fireplace',
  'first-page',
  'fit-screen',
  'fitness-center',
  'flag',
  'flaky',
  'flare',
  'flash-auto',
  'flash-off',
  'flash-on',
  'flight',
  'flight-land',
  'flight-takeoff',
  'flip',
  'flip-camera-android',
  'flip-camera-ios',
  'flip-to-back',
  'flip-to-front',
  'folder',
  'folder-open',
  'folder-shared',
  'folder-special',
  'follow-the-signs',
  'font-download',
  'food-bank',
  'format-align-center',
  'format-align-justify',
  'format-align-left',
  'format-align-right',
  'format-bold',
  'format-clear',
  'format-color-fill',
  'format-color-reset',
  'format-color-text',
  'format-indent-decrease',
  'format-indent-increase',
  'format-italic',
  'format-line-spacing',
  'format-list-bulleted',
  'format-list-numbered',
  'format-list-numbered-rtl',
  'format-paint',
  'format-quote',
  'format-shapes',
  'format-size',
  'format-strikethrough',
  'format-textdirection-l-to-r',
  'format-textdirection-r-to-l',
  'format-underline',
  'format-underlined',
  'forum',
  'forward',
  'forward-10',
  'forward-30',
  'forward-5',
  'forward-to-inbox',
  'foundation',
  'free-breakfast',
  'fullscreen',
  'fullscreen-exit',
  'functions',
  'g-translate',
  'gamepad',
  'games',
  'gavel',
  'gesture',
  'get-app',
  'gif',
  'goat',
  'golf-course',
  'gps-fixed',
  'gps-not-fixed',
  'gps-off',
  'grade',
  'gradient',
  'grading',
  'grain',
  'graphic-eq',
  'grass',
  'grid-off',
  'grid-on',
  'grid-view',
  'group',
  'group-add',
  'group-work',
  'groups',
  'hail',
  'handyman',
  'hardware',
  'hd',
  'hdr-enhanced-select',
  'hdr-off',
  'hdr-on',
  'hdr-strong',
  'hdr-weak',
  'headset',
  'headset-mic',
  'headset-off',
  'healing',
  'hearing',
  'hearing-disabled',
  'height',
  'help',
  'help-center',
  'help-outline',
  'high-quality',
  'highlight',
  'highlight-alt',
  'highlight-off',
  'highlight-remove',
  'history',
  'history-edu',
  'history-toggle-off',
  'home',
  'home-filled',
  'home-repair-service',
  'home-work',
  'horizontal-distribute',
  'horizontal-rule',
  'horizontal-split',
  'hot-tub',
  'hotel',
  'hourglass-bottom',
  'hourglass-disabled',
  'hourglass-empty',
  'hourglass-full',
  'hourglass-top',
  'house',
  'house-siding',
  'how-to-reg',
  'how-to-vote',
  'http',
  'https',
  'hvac',
  'icecream',
  'image',
  'image-aspect-ratio',
  'image-not-supported',
  'image-search',
  'imagesearch-roller',
  'import-contacts',
  'import-export',
  'important-devices',
  'inbox',
  'indeterminate-check-box',
  'info',
  'info-outline',
  'input',
  'insert-chart',
  'insert-chart-outlined',
  'insert-comment',
  'insert-drive-file',
  'insert-emoticon',
  'insert-invitation',
  'insert-link',
  'insert-photo',
  'insights',
  'integration-instructions',
  'inventory',
  'invert-colors',
  'invert-colors-off',
  'invert-colors-on',
  'ios-share',
  'iso',
  'keyboard',
  'keyboard-arrow-down',
  'keyboard-arrow-left',
  'keyboard-arrow-right',
  'keyboard-arrow-up',
  'keyboard-backspace',
  'keyboard-capslock',
  'keyboard-control',
  'keyboard-hide',
  'keyboard-return',
  'keyboard-tab',
  'keyboard-voice',
  'king-bed',
  'kitchen',
  'label',
  'label-important',
  'label-important-outline',
  'label-off',
  'label-outline',
  'landscape',
  'language',
  'laptop',
  'laptop-chromebook',
  'laptop-mac',
  'laptop-windows',
  'last-page',
  'launch',
  'layers',
  'layers-clear',
  'leaderboard',
  'leak-add',
  'leak-remove',
  'leave-bags-at-home',
  'legend-toggle',
  'lens',
  'library-add',
  'library-add-check',
  'library-books',
  'library-music',
  'lightbulb',
  'lightbulb-outline',
  'line-style',
  'line-weight',
  'linear-scale',
  'link',
  'link-off',
  'linked-camera',
  'liquor',
  'list',
  'list-alt',
  'live-help',
  'live-tv',
  'local-activity',
  'local-airport',
  'local-atm',
  'local-attraction',
  'local-bar',
  'local-cafe',
  'local-car-wash',
  'local-convenience-store',
  'local-dining',
  'local-drink',
  'local-fire-department',
  'local-florist',
  'local-gas-station',
  'local-grocery-store',
  'local-hospital',
  'local-hotel',
  'local-laundry-service',
  'local-library',
  'local-mall',
  'local-movies',
  'local-offer',
  'local-parking',
  'local-pharmacy',
  'local-phone',
  'local-pizza',
  'local-play',
  'local-police',
  'local-post-office',
  'local-print-shop',
  'local-printshop',
  'local-restaurant',
  'local-see',
  'local-shipping',
  'local-taxi',
  'location-city',
  'location-disabled',
  'location-history',
  'location-off',
  'location-on',
  'location-pin',
  'location-searching',
  'lock',
  'lock-clock',
  'lock-open',
  'lock-outline',
  'login',
  'logout',
  'looks',
  'looks-3',
  'looks-4',
  'looks-5',
  'looks-6',
  'looks-one',
  'looks-two',
  'loop',
  'loupe',
  'low-priority',
  'loyalty',
  'luggage',
  'lunch-dining',
  'mail',
  'mail-outline',
  'map',
  'maps-ugc',
  'margin',
  'mark-as-unread',
  'mark-chat-read',
  'mark-chat-unread',
  'mark-email-read',
  'mark-email-unread',
  'markunread',
  'markunread-mailbox',
  'masks',
  'maximize',
  'mediation',
  'medical-services',
  'meeting-room',
  'memory',
  'menu',
  'menu-book',
  'menu-open',
  'merge-type',
  'message',
  'messenger',
  'messenger-outline',
  'mic',
  'mic-external-off',
  'mic-external-on',
  'mic-none',
  'mic-off',
  'microwave',
  'military-tech',
  'minimize',
  'miscellaneous-services',
  'missed-video-call',
  'mms',
  'mobile-friendly',
  'mobile-off',
  'mobile-screen-share',
  'mode-comment',
  'mode-edit',
  'model-training',
  'monetization-on',
  'money',
  'money-off',
  'monitor',
  'monochrome-photos',
  'mood',
  'mood-bad',
  'moped',
  'more',
  'more-horiz',
  'more-time',
  'more-vert',
  'motion-photos-off',
  'motion-photos-on',
  'motion-photos-pause',
  'motion-photos-paused',
  'motorcycle',
  'mouse',
  'move-to-inbox',
  'movie',
  'movie-creation',
  'movie-filter',
  'mp',
  'multiline-chart',
  'multiple-stop',
  'multitrack-audio',
  'museum',
  'music-note',
  'music-off',
  'music-video',
  'my-library-add',
  'my-library-books',
  'my-library-music',
  'my-location',
  'nat',
  'nature',
  'nature-people',
  'navigate-before',
  'navigate-next',
  'navigation',
  'near-me',
  'near-me-disabled',
  'network-cell',
  'network-check',
  'network-locked',
  'network-wifi',
  'new-releases',
  'next-plan',
  'next-week',
  'nfc',
  'night-shelter',
  'nightlife',
  'nightlight-round',
  'nights-stay',
  'no-backpack',
  'no-cell',
  'no-drinks',
  'no-encryption',
  'no-flash',
  'no-food',
  'no-luggage',
  'no-meals',
  'no-meals-ouline',
  'no-meeting-room',
  'no-photography',
  'no-sim',
  'no-stroller',
  'no-transfer',
  'north',
  'north-east',
  'north-west',
  'not-accessible',
  'not-interested',
  'not-listed-location',
  'not-started',
  'note',
  'note-add',
  'notes',
  'notification-important',
  'notifications',
  'notifications-active',
  'notifications-none',
  'notifications-off',
  'notifications-on',
  'notifications-paused',
  'now-wallpaper',
  'now-widgets',
  'offline-bolt',
  'offline-pin',
  'offline-share',
  'ondemand-video',
  'online-prediction',
  'opacity',
  'open-in-browser',
  'open-in-full',
  'open-in-new',
  'open-with',
  'outbond',
  'outbox',
  'outdoor-grill',
  'outgoing-mail',
  'outlet',
  'outlined-flag',
  'padding',
  'pages',
  'pageview',
  'palette',
  'pan-tool',
  'panorama',
  'panorama-fish-eye',
  'panorama-fisheye',
  'panorama-horizontal',
  'panorama-horizontal-select',
  'panorama-photosphere',
  'panorama-photosphere-select',
  'panorama-vertical',
  'panorama-vertical-select',
  'panorama-wide-angle',
  'panorama-wide-angle-select',
  'park',
  'party-mode',
  'pause',
  'pause-circle-filled',
  'pause-circle-outline',
  'pause-presentation',
  'payment',
  'payments',
  'pedal-bike',
  'pending',
  'pending-actions',
  'people',
  'people-alt',
  'people-outline',
  'perm-camera-mic',
  'perm-contact-cal',
  'perm-contact-calendar',
  'perm-data-setting',
  'perm-device-info',
  'perm-device-information',
  'perm-identity',
  'perm-media',
  'perm-phone-msg',
  'perm-scan-wifi',
  'person',
  'person-add',
  'person-add-alt',
  'person-add-alt-1',
  'person-add-disabled',
  'person-outline',
  'person-pin',
  'person-pin-circle',
  'person-remove',
  'person-remove-alt-1',
  'person-search',
  'personal-video',
  'pest-control',
  'pest-control-rodent',
  'pets',
  'phone',
  'phone-android',
  'phone-bluetooth-speaker',
  'phone-callback',
  'phone-disabled',
  'phone-enabled',
  'phone-forwarded',
  'phone-in-talk',
  'phone-iphone',
  'phone-locked',
  'phone-missed',
  'phone-paused',
  'phonelink',
  'phonelink-erase',
  'phonelink-lock',
  'phonelink-off',
  'phonelink-ring',
  'phonelink-setup',
  'photo',
  'photo-album',
  'photo-camera',
  'photo-camera-back',
  'photo-camera-front',
  'photo-filter',
  'photo-library',
  'photo-size-select-actual',
  'photo-size-select-large',
  'photo-size-select-small',
  'picture-as-pdf',
  'picture-in-picture',
  'picture-in-picture-alt',
  'pie-chart',
  'pie-chart-outlined',
  'pin-drop',
  'pivot-table-chart',
  'place',
  'plagiarism',
  'play-arrow',
  'play-circle-fill',
  'play-circle-filled',
  'play-circle-outline',
  'play-disabled',
  'play-for-work',
  'playlist-add',
  'playlist-add-check',
  'playlist-play',
  'plumbing',
  'plus-one',
  'point-of-sale',
  'policy',
  'poll',
  'polymer',
  'pool',
  'portable-wifi-off',
  'portrait',
  'post-add',
  'power',
  'power-input',
  'power-off',
  'power-settings-new',
  'pregnant-woman',
  'present-to-all',
  'preview',
  'print',
  'print-disabled',
  'priority-high',
  'privacy-tip',
  'psychology',
  'public',
  'public-off',
  'publish',
  'published-with-changes',
  'push-pin',
  'qr-code',
  'qr-code-2',
  'qr-code-scanner',
  'query-builder',
  'question-answer',
  'queue',
  'queue-music',
  'queue-play-next',
  'quick-contacts-dialer',
  'quick-contacts-mail',
  'quickreply',
  'radio',
  'radio-button-checked',
  'radio-button-off',
  'radio-button-on',
  'radio-button-unchecked',
  'railway-alert',
  'ramen-dining',
  'rate-review',
  'read-more',
  'receipt',
  'receipt-long',
  'recent-actors',
  'recommend',
  'record-voice-over',
  'redeem',
  'redo',
  'reduce-capacity',
  'refresh',
  'remove',
  'remove-circle',
  'remove-circle-outline',
  'remove-done',
  'remove-from-queue',
  'remove-moderator',
  'remove-red-eye',
  'remove-shopping-cart',
  'reorder',
  'repeat',
  'repeat-on',
  'repeat-one',
  'repeat-one-on',
  'replay',
  'replay-10',
  'replay-30',
  'replay-5',
  'replay-circle-filled',
  'reply',
  'reply-all',
  'report',
  'report-off',
  'report-problem',
  'request-page',
  'request-quote',
  'reset-tv',
  'restaurant',
  'restaurant-menu',
  'restore',
  'restore-from-trash',
  'restore-page',
  'rice-bowl',
  'ring-volume',
  'roofing',
  'room',
  'room-preferences',
  'room-service',
  'rotate-90-degrees-ccw',
  'rotate-left',
  'rotate-right',
  'rounded-corner',
  'router',
  'rowing',
  'rss-feed',
  'rtt',
  'rule',
  'rule-folder',
  'run-circle',
  'rv-hookup',
  'sanitizer',
  'satellite',
  'save',
  'save-alt',
  'saved-search',
  'scanner',
  'scatter-plot',
  'schedule',
  'schedule-send',
  'school',
  'science',
  'score',
  'screen-lock-landscape',
  'screen-lock-portrait',
  'screen-lock-rotation',
  'screen-rotation',
  'screen-search-desktop',
  'screen-share',
  'sd',
  'sd-card',
  'sd-storage',
  'search',
  'search-off',
  'security',
  'segment',
  'select-all',
  'self-improvement',
  'send',
  'send-and-archive',
  'send-to-mobile',
  'sensor-door',
  'sensor-window',
  'sentiment-dissatisfied',
  'sentiment-neutral',
  'sentiment-satisfied',
  'sentiment-satisfied-alt',
  'sentiment-very-dissatisfied',
  'sentiment-very-satisfied',
  'set-meal',
  'settings',
  'settings-applications',
  'settings-backup-restore',
  'settings-bluetooth',
  'settings-brightness',
  'settings-cell',
  'settings-display',
  'settings-ethernet',
  'settings-input-antenna',
  'settings-input-component',
  'settings-input-composite',
  'settings-input-hdmi',
  'settings-input-svideo',
  'settings-overscan',
  'settings-phone',
  'settings-power',
  'settings-remote',
  'settings-system-daydream',
  'settings-voice',
  'share',
  'shield',
  'shop',
  'shop-two',
  'shopping-bag',
  'shopping-basket',
  'shopping-cart',
  'short-text',
  'show-chart',
  'shuffle',
  'shuffle-on',
  'shutter-speed',
  'sick',
  'signal-cellular-0-bar',
  'signal-cellular-4-bar',
  'signal-cellular-alt',
  'signal-cellular-connected-no-internet-4-bar',
  'signal-cellular-no-sim',
  'signal-cellular-null',
  'signal-cellular-off',
  'signal-wifi-0-bar',
  'signal-wifi-4-bar',
  'signal-wifi-4-bar-lock',
  'signal-wifi-off',
  'sim-card',
  'sim-card-alert',
  'single-bed',
  'skip-next',
  'skip-previous',
  'slideshow',
  'slow-motion-video',
  'smart-button',
  'smartphone',
  'smoke-free',
  'smoking-rooms',
  'sms',
  'sms-failed',
  'snippet-folder',
  'snooze',
  'soap',
  'sort',
  'sort-by-alpha',
  'source',
  'south',
  'south-east',
  'south-west',
  'spa',
  'space-bar',
  'speaker',
  'speaker-group',
  'speaker-notes',
  'speaker-notes-off',
  'speaker-phone',
  'speed',
  'spellcheck',
  'sports',
  'sports-bar',
  'sports-baseball',
  'sports-basketball',
  'sports-cricket',
  'sports-esports',
  'sports-football',
  'sports-golf',
  'sports-handball',
  'sports-hockey',
  'sports-kabaddi',
  'sports-mma',
  'sports-motorsports',
  'sports-rugby',
  'sports-soccer',
  'sports-tennis',
  'sports-volleyball',
  'square-foot',
  'stacked-bar-chart',
  'stacked-line-chart',
  'stairs',
  'star',
  'star-border',
  'star-half',
  'star-outline',
  'star-rate',
  'stars',
  'stay-current-landscape',
  'stay-current-portrait',
  'stay-primary-landscape',
  'stay-primary-portrait',
  'sticky-note-2',
  'stop',
  'stop-circle',
  'stop-screen-share',
  'storage',
  'store',
  'store-mall-directory',
  'storefront',
  'straighten',
  'stream',
  'streetview',
  'strikethrough-s',
  'stroller',
  'style',
  'subdirectory-arrow-left',
  'subdirectory-arrow-right',
  'subject',
  'subscript',
  'subscriptions',
  'subtitles',
  'subtitles-off',
  'subway',
  'superscript',
  'supervised-user-circle',
  'supervisor-account',
  'support',
  'support-agent',
  'surround-sound',
  'swap-calls',
  'swap-horiz',
  'swap-horizontal-circle',
  'swap-vert',
  'swap-vert-circle',
  'swap-vertical-circle',
  'swipe',
  'switch-account',
  'switch-camera',
  'switch-left',
  'switch-right',
  'switch-video',
  'sync',
  'sync-alt',
  'sync-disabled',
  'sync-problem',
  'system-update',
  'system-update-alt',
  'system-update-tv',
  'tab',
  'tab-unselected',
  'table-chart',
  'table-rows',
  'table-view',
  'tablet',
  'tablet-android',
  'tablet-mac',
  'tag',
  'tag-faces',
  'takeout-dining',
  'tap-and-play',
  'tapas',
  'taxi-alert',
  'terrain',
  'text-fields',
  'text-format',
  'text-rotate-up',
  'text-rotate-vertical',
  'text-rotation-angledown',
  'text-rotation-angleup',
  'text-rotation-down',
  'text-rotation-none',
  'text-snippet',
  'textsms',
  'texture',
  'theater-comedy',
  'theaters',
  'thumb-down',
  'thumb-down-alt',
  'thumb-down-off-alt',
  'thumb-up',
  'thumb-up-alt',
  'thumb-up-off-alt',
  'thumbs-up-down',
  'time-to-leave',
  'timelapse',
  'timeline',
  'timer',
  'timer-10',
  'timer-3',
  'timer-off',
  'title',
  'toc',
  'today',
  'toggle-off',
  'toggle-on',
  'toll',
  'tonality',
  'topic',
  'touch-app',
  'tour',
  'toys',
  'track-changes',
  'traffic',
  'train',
  'tram',
  'transfer-within-a-station',
  'transform',
  'transit-enterexit',
  'translate',
  'trending-down',
  'trending-flat',
  'trending-neutral',
  'trending-up',
  'trip-origin',
  'tty',
  'tune',
  'turned-in',
  'turned-in-not',
  'tv',
  'tv-off',
  'two-wheeler',
  'umbrella',
  'unarchive',
  'undo',
  'unfold-less',
  'unfold-more',
  'unpublished',
  'unsubscribe',
  'update',
  'update-disabled',
  'upgrade',
  'upload-file',
  'usb',
  'verified',
  'verified-user',
  'vertical-align-bottom',
  'vertical-align-center',
  'vertical-align-top',
  'vertical-distribute',
  'vertical-split',
  'vibration',
  'video-call',
  'video-collection',
  'video-label',
  'video-library',
  'video-settings',
  'videocam',
  'videocam-off',
  'videogame-asset',
  'view-agenda',
  'view-array',
  'view-carousel',
  'view-column',
  'view-comfortable',
  'view-comfy',
  'view-compact',
  'view-day',
  'view-headline',
  'view-in-ar',
  'view-list',
  'view-module',
  'view-quilt',
  'view-sidebar',
  'view-stream',
  'view-week',
  'vignette',
  'visibility',
  'visibility-off',
  'voice-chat',
  'voice-over-off',
  'voicemail',
  'volume-down',
  'volume-mute',
  'volume-off',
  'volume-up',
  'volunteer-activism',
  'vpn-key',
  'vpn-lock',
  'wallet-giftcard',
  'wallet-membership',
  'wallet-travel',
  'wallpaper',
  'warning',
  'wash',
  'watch',
  'watch-later',
  'water-damage',
  'waterfall-chart',
  'waves',
  'wb-auto',
  'wb-cloudy',
  'wb-incandescent',
  'wb-iridescent',
  'wb-shade',
  'wb-sunny',
  'wb-twighlight',
  'wc',
  'web',
  'web-asset',
  'weekend',
  'west',
  'whatshot',
  'wheelchair-pickup',
  'where-to-vote',
  'widgets',
  'wifi',
  'wifi-calling',
  'wifi-lock',
  'wifi-off',
  'wifi-protected-setup',
  'wifi-tethering',
  'wine-bar',
  'work',
  'work-off',
  'work-outline',
  'workspaces-filled',
  'workspaces-outline',
  'wrap-text',
  'wrong-location',
  'wysiwyg',
  'youtube-searched-for',
  'zoom-in',
  'zoom-out',
  'zoom-out-map',
];
