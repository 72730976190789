import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../models/data';
import { userService } from '../../services';
import { UserState } from './types';

export const getUsers = createAsyncThunk(
  'users/getUsers',
  async ({ page, status }: { page?: number; status?: string }) => {
    return userService.getUsers(page, status);
  },
);

export const getUser = createAsyncThunk('users/getUser', async (id: string) => {
  return userService.getUser(id);
});

export const updateUser = createAsyncThunk('users/updateUser', async (user: User) => {
  return userService.update(user);
});

const initialState: UserState = {
  currentUser: null,
  activeUser: null,
  users: [],
  loading: false,
  error: null,
  currentPage: 1,
  pageCount: null,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    updateCurrentPage(state, { payload }: PayloadAction<number>) {
      state.currentPage = payload;
    },

    setUsers(state, { payload }: PayloadAction<User[]>) {
      state.users = payload;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.users;
        state.pageCount = action.payload.pageCount;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load users.';
      });

    builder
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.activeUser = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load user.';
      });

    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.activeUser = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to update user.';
      });
  },
});

export const usersActions = { ...usersSlice.actions, getUsers, getUser, updateUser };

export type UsersSlice = {
  [usersSlice.name]: ReturnType<typeof usersSlice['reducer']>;
};
