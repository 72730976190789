import { useFormik } from 'formik';
import { Box, Button, Divider, FormControl, Heading, Input, Link, ScrollView, VStack } from 'native-base';
import React, { useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { useDialog } from '../../contexts';
import { GalleryItem } from '../../models/data';
import { useAppDispatch, useAppSelector } from '../../store';
import { galleryActions } from '../../store/actions';

const galleryItemSchema = Yup.object().shape({
  caption: Yup.string().required('Required'),
  url: Yup.string().required('Required').url('The URL must be valid'),
});

export interface GalleryFormProps {
  galleryItem?: GalleryItem;
  onSubmit: () => void;
  onDelete?: () => void;
}

export const GalleryForm: React.FC<GalleryFormProps> = ({ galleryItem, onSubmit, onDelete }) => {
  const dispatch = useAppDispatch();
  const { showAlert } = useDialog();

  const galleryState = useAppSelector((state) => state.gallery);
  const { loading: galleryItemsLoading, error } = galleryState;

  const submit = useCallback(
    async (values: Partial<GalleryItem>) => {
      const galleryItemData: GalleryItem = {
        ...galleryItem,
        ...values,
      };

      let nextGalleryItem: GalleryItem;

      if (values.id) {
        nextGalleryItem = await dispatch(galleryActions.updateGalleryItem(galleryItemData)).unwrap();
      } else {
        nextGalleryItem = await dispatch(galleryActions.createGalleryItem(galleryItemData)).unwrap();
      }

      if (nextGalleryItem) {
        onSubmit();
      }
    },
    [onSubmit, galleryItem],
  );

  const formik = useFormik({
    initialValues: {
      caption: galleryItem?.caption || '',
      url: galleryItem?.url || '',
    },
    validationSchema: galleryItemSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });

  useEffect(() => {
    if (error) {
      dispatch(galleryActions.updateError(null));
      showAlert({ title: 'Create failed', message: 'An error occurred. Please try again.' });
    }
  }, [error, showAlert]);

  useEffect(() => {
    if (!galleryItem) {
      return;
    }

    formik.setFieldValue('caption', galleryItem.caption);
    formik.setFieldValue('url', galleryItem.url);
  }, [galleryItem]);

  return (
    <ScrollView>
      <Box p={4}>
        <VStack mb={4}>
          <FormControl isInvalid={formik.touched.url && formik.errors.url ? true : false}>
            <FormControl.Label>
              <Heading size="sm">URL</Heading>
            </FormControl.Label>
            <FormControl.ErrorMessage>
              {formik.touched.url && formik.errors.url ? formik.errors.url : null}
            </FormControl.ErrorMessage>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('url')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.url}
              isDisabled={galleryItemsLoading}
            />
          </FormControl>
          <FormControl isInvalid={formik.touched.caption && formik.errors.caption ? true : false}>
            <FormControl.Label>
              <Heading size="sm">Caption</Heading>
            </FormControl.Label>
            <FormControl.ErrorMessage>
              {formik.touched.caption && formik.errors.caption ? formik.errors.caption : null}
            </FormControl.ErrorMessage>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('caption')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.caption}
              isDisabled={galleryItemsLoading}
            />
          </FormControl>
        </VStack>

        <Divider my={4} />

        <Button.Group space={4} justifyContent="flex-end">
          {galleryItem && (
            <Button onPress={onDelete} colorScheme="danger" variant="subtle" isDisabled={galleryItemsLoading}>
              DELETE
            </Button>
          )}
          <Link href="/gallery">
            <Button colorScheme="secondary" variant="subtle" isDisabled={galleryItemsLoading}>
              CANCEL
            </Button>
          </Link>
          <Button onPress={() => formik.handleSubmit()} colorScheme="primary" isDisabled={galleryItemsLoading}>
            SAVE
          </Button>
        </Button.Group>
      </Box>
    </ScrollView>
  );
};
