import { extendTheme } from 'native-base';
import { StyleSheet } from 'react-native';

export const colors = {
  black: '#232323',
  darkGrey: '#8C8B8B',
  darkestGrey: '#464646',
  grey: '#8C8B8B',
  lightGrey: '#EFEFEF',
  lightestGrey: '#F8F8F8',
  primary: '#619F94',
  secondary: '#00495B',
  white: '#fff',
  red: '#d7263d',
  yellow: '#FBAF3E',
};

export const theme = extendTheme({
  colors: {
    // Add new color
    primary: {
      50: '#eff5f4',
      100: '#dfecea',
      200: '#d0e2df',
      300: '#c0d9d4',
      400: '#a0c5bf',
      500: '#81b2a9',
      600: colors.primary,
      700: '#4e7f76',
      800: '#3a5f59',
      900: '#27403b',
    },
    secondary: {
      50: '#e6edef',
      100: '#ccdbde',
      200: '#b3c8ce',
      300: '#99b6bd',
      400: '#66929d',
      500: '#336d7c',
      600: colors.secondary,
      700: '#003a49',
      800: '#002c37',
      900: '#001d24',
    },
  },
  components: {
    ScrollView: {
      baseStyle: ({ colorMode }) => ({ backgroundColor: colorMode === 'dark' ? colors.black : colors.white }),
    },
  },
  fontConfig: {
    Avenir: {
      100: {
        normal: 'Avenir Next',
        italic: 'Avenir Next Italic',
      },
      200: {
        normal: 'Avenir Next',
        italic: 'Avenir Next Italic',
      },
      300: {
        normal: 'Avenir Next',
        italic: 'Avenir Next Italic',
      },
      400: {
        normal: 'Avenir Next',
        italic: 'Avenir Next Italic',
      },
      500: {
        normal: 'Avenir Next',
        italic: 'Avenir Next Italic',
      },
      600: {
        normal: 'Avenir Bold',
        italic: 'Avenir Bold Italic',
      },
      700: {
        normal: 'Avenir Bold',
        italic: 'Avenir Bold Italic',
      },
      800: {
        normal: 'Avenir Bold',
        italic: 'Avenir Bold Italic',
      },
      900: {
        normal: 'Avenir Bold',
        italic: 'Avenir Bold Italic',
      },
    },
  },
  fonts: {
    heading: 'Avenir Next',
    body: 'Avenir Next',
    mono: 'Courier New',
  },
});

export const rootStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  centeredContent: {
    flex: 1,
    justifyContent: 'center' as 'center',
    alignItems: 'center' as 'center',
  },
  centeredText: {
    textAlign: 'center' as 'center',
  },
});
