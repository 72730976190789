import React, { useCallback } from 'react';
import { NotificationForm } from '../../components/notifications';

export const NotificationCreateScreen: React.FC<any> = () => {
  const onSubmit = useCallback(() => {
    window.location.href = '/notifications';
  }, []);

  return <NotificationForm onSubmit={onSubmit} />;
};
