import { Button, Text, View } from 'native-base';
import React, { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { useAppDispatch } from '../store';
import { authActions } from '../store/actions';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    width: '100%',
    padding: 20,
  },

  topContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  middleContainer: {
    flex: 1,
    justifyContent: 'flex-start',
  },

  bottomContainer: {
    justifyContent: 'flex-end',
    margin: 20,
    padding: 10,
  },
  text: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 10,
    textAlign: 'center',
  },
  subtext: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 12,
  },
});

export const UnauthorizedScreen: React.FC<any> = () => {
  const dispatch = useAppDispatch();

  const signOut = useCallback(() => {
    dispatch(authActions.signOutUser(true));
  }, [dispatch]);

  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <Text style={styles.text}>UNAUTHORIZED ACCESS</Text>
        <Text style={styles.subtext}>
          You are not authorized to access this application. Please contact a system administrator.
        </Text>
      </View>

      <View style={styles.middleContainer}></View>
      <View style={styles.bottomContainer}>
        <Button variant="ghost" style={{ marginTop: 20 }} onPress={() => signOut()}>
          <Text>Log Out</Text>
        </Button>
      </View>
    </View>
  );
};
