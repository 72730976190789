import { GalleryItem } from '../models/data';
import BaseService from './BaseService';

export class GalleryService extends BaseService {
  async getGalleryItems() {
    return (await this.getJson<GalleryItem[]>('/gallery')).data;
  }

  async getGalleryItem(id: string) {
    return (await this.getJson<GalleryItem>('/gallery/' + id)).data;
  }

  async store(gallery: Partial<GalleryItem>) {
    return (await this.postJson<GalleryItem>('/gallery', gallery)).data;
  }

  async update(gallery: Partial<GalleryItem>) {
    return (await this.putJson<GalleryItem>('/gallery/' + gallery.id, gallery)).data;
  }

  async delete(gallery: GalleryItem) {
    return (await this.deleteJson<GalleryItem>('/gallery/' + gallery.id)).data;
  }
}

export default new GalleryService();
