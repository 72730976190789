import { MaterialIcons } from '@expo/vector-icons';
import { useFormik } from 'formik';
import { Box, Button, Divider, FormControl, Heading, Icon, Input, Link, ScrollView, Select, VStack } from 'native-base';
import React, { useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { MATERIAL_ICON_VALUES } from '../../constants/Icons';
import { useDialog } from '../../contexts';
import { Resource } from '../../models/data';
import { useAppDispatch, useAppSelector } from '../../store';
import { resourcesActions } from '../../store/actions';
import { colors } from '../../styles';

const resourceProfileSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  link: Yup.string().required('Required').url('The URL must be valid'),
  icon: Yup.string(),
});

export interface ResourcesFormProps {
  resource?: Resource;
  onSubmit: () => void;
  onDelete?: () => void;
}

export const ResourcesForm: React.FC<ResourcesFormProps> = ({ resource, onSubmit, onDelete }) => {
  const dispatch = useAppDispatch();
  const { showAlert } = useDialog();

  const resourceState = useAppSelector((state) => state.resources);
  const { loading: resourcesLoading, error } = resourceState;

  const submit = useCallback(
    async (values: Resource) => {
      const resourceData: Resource = {
        ...resource,
        ...values,
      };

      let nextResource: Resource;

      if (values.id) {
        nextResource = await dispatch(resourcesActions.updateResource(resourceData)).unwrap();
      } else {
        nextResource = await dispatch(resourcesActions.createResource(resourceData)).unwrap();
      }

      if (nextResource) {
        onSubmit();
      }
    },
    [onSubmit, resource],
  );

  const formik = useFormik({
    initialValues: {
      link: resource?.link || '',
      name: resource?.name || '',
      icon: resource?.icon || '',
    },
    validationSchema: resourceProfileSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });

  useEffect(() => {
    if (error) {
      dispatch(resourcesActions.updateError(null));
      showAlert({ title: 'Create failed', message: 'An error occurred. Please try again.' });
    }
  }, [error, showAlert]);

  useEffect(() => {
    if (!resource) {
      return;
    }

    formik.setFieldValue('link', resource.link);
    formik.setFieldValue('name', resource.name);
    formik.setFieldValue('icon', resource.icon);
  }, [resource]);

  return (
    <ScrollView>
      <Box p={4}>
        <VStack mb={4}>
          <FormControl isInvalid={formik.touched.name && formik.errors.name ? true : false}>
            <FormControl.Label>
              <Heading size="sm">Name</Heading>
            </FormControl.Label>
            <FormControl.ErrorMessage>
              {formik.touched.name && formik.errors.name ? formik.errors.name : null}
            </FormControl.ErrorMessage>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('name')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.name}
              isDisabled={resourcesLoading}
            />
          </FormControl>
          <FormControl isInvalid={formik.touched.link && formik.errors.link ? true : false}>
            <FormControl.Label>
              <Heading size="sm">URL</Heading>
            </FormControl.Label>
            <FormControl.ErrorMessage>
              {formik.touched.link && formik.errors.link ? formik.errors.link : null}
            </FormControl.ErrorMessage>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('link')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.link}
              isDisabled={resourcesLoading}
            />
          </FormControl>
          <FormControl mb={4} isInvalid={formik.touched.icon && formik.errors.icon ? true : false}>
            <FormControl.Label>
              <Heading size="sm">Icon</Heading>
            </FormControl.Label>
            <FormControl.ErrorMessage>
              {formik.touched.icon && formik.errors.icon ? formik.errors.icon : null}
            </FormControl.ErrorMessage>
            <Select
              selectedValue={formik.values.icon}
              onValueChange={formik.handleChange('icon')}
              isDisabled={resourcesLoading}
            >
              <Select.Item label={null} value={null} />;
              {MATERIAL_ICON_VALUES.map((iconValue, index) => {
                return <Select.Item label={iconValue} value={iconValue} key={index} />;
              })}
            </Select>
          </FormControl>
          <VStack space={2}>
            <Heading size="sm">Icon Preview</Heading>
            <Icon as={MaterialIcons} name={formik.values.icon} size={48} color={colors.primary} />
          </VStack>
        </VStack>

        <Divider my={4} />

        <Button.Group space={4} justifyContent="flex-end">
          {resource && (
            <Button onPress={onDelete} colorScheme="danger" variant="subtle" isDisabled={resourcesLoading}>
              DELETE
            </Button>
          )}
          <Link href="/resources">
            <Button colorScheme="secondary" variant="subtle" isDisabled={resourcesLoading}>
              CANCEL
            </Button>
          </Link>
          <Button onPress={() => formik.handleSubmit()} colorScheme="primary" isDisabled={resourcesLoading}>
            SAVE
          </Button>
        </Button.Group>
      </Box>
    </ScrollView>
  );
};
