import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import { HStack } from 'native-base';
import React from 'react';
import { Image, useWindowDimensions } from 'react-native';
import { DrawerContent } from '../components';
import HeaderLeft from '../components/HeaderLeft';
import config from '../config';
import {
  CampaignDetailScreen,
  CampaignsScreen,
  DashboardScreen,
  NotificationCreateScreen,
  NotificationDetailScreen,
  NotificationsScreen,
  ResourceCreateScreen,
  ResourceDetailScreen,
  ResourcesScreen,
  RoleDetailScreen,
  RolesScreen,
  SettingDetailScreen,
  SettingsScreen,
  UserDetailScreen,
  UserProfileScreen,
  UsersScreen,
} from '../screens';
import { FeedCreateScreen, FeedDetailScreen, FeedScreen } from '../screens/feed';
import { GalleryCreateScreen, GalleryDetailScreen, GalleryScreen } from '../screens/gallery';

const DRAWER_VISIBILITY_BREAKPOINT = 768;

const defaultScreenOptions = (title: string, otherOptions: any = {}) => {
  const dimensions = useWindowDimensions();

  const commonOptions = {
    title,
    headerTintColor: '#044959',
    headerLeft: () => (
      <HStack style={{ alignItems: 'center', height: 40 }}>
        {dimensions.width < DRAWER_VISIBILITY_BREAKPOINT && <HeaderLeft />}
        <Image
          // eslint-disable-next-line global-require
          source={require('../../assets/images/cropped-rtsws-widget-navy-2021-192x192.jpeg')}
          resizeMode="contain"
          style={{ width: 48, height: 48, left: 10 }}
        />
      </HStack>
    ),
  };

  return { ...commonOptions, ...otherOptions };
};

const Drawer = createDrawerNavigator();

function DrawerComponent() {
  const dimensions = useWindowDimensions();

  return (
    <Drawer.Navigator
      screenOptions={{
        drawerHideStatusBarOnOpen: true,
        drawerType: dimensions.width >= DRAWER_VISIBILITY_BREAKPOINT ? 'permanent' : 'slide',
        drawerStyle: {
          width: 240,
        },
      }}
      drawerContent={(props) => <DrawerContent {...props} />}
    >
      <Drawer.Screen
        name="Dashboard"
        component={DashboardScreen}
        options={() => defaultScreenOptions('Admin Dashboard')}
      />
      <Drawer.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={() => defaultScreenOptions('Notifications')}
      />
      <Drawer.Screen
        name="NotificationCreate"
        component={NotificationCreateScreen}
        options={() => defaultScreenOptions('Create Notification')}
      />
      <Drawer.Screen
        name="NotificationDetail"
        component={NotificationDetailScreen}
        options={() => defaultScreenOptions('Notification Details')}
      />
      <Drawer.Screen name="Resources" component={ResourcesScreen} options={() => defaultScreenOptions('Resources')} />
      <Drawer.Screen
        name="ResourceCreate"
        component={ResourceCreateScreen}
        options={() => defaultScreenOptions('Create Resource')}
      />
      <Drawer.Screen
        name="ResourceDetail"
        component={ResourceDetailScreen}
        options={() => defaultScreenOptions('Update Resource')}
      />
      <Drawer.Screen name="Settings" component={SettingsScreen} options={() => defaultScreenOptions('Settings')} />
      <Drawer.Screen
        name="SettingDetail"
        component={SettingDetailScreen}
        options={() => defaultScreenOptions('Update Setting')}
      />
      <Drawer.Screen name="Users" component={UsersScreen} options={() => defaultScreenOptions('Users')} />
      <Drawer.Screen
        name="UserDetail"
        component={UserDetailScreen}
        options={() => defaultScreenOptions('Update User')}
      />
      <Drawer.Screen
        name="Campaigns"
        component={CampaignsScreen}
        options={() => defaultScreenOptions('Campaign Settings')}
      />
      <Drawer.Screen
        name="CampaignDetail"
        component={CampaignDetailScreen}
        options={() => defaultScreenOptions('Update Campaign Settings')}
      />
      <Drawer.Screen name="Roles" component={RolesScreen} options={() => defaultScreenOptions('Role Settings')} />
      <Drawer.Screen
        name="RoleDetail"
        component={RoleDetailScreen}
        options={() => defaultScreenOptions('Update Role Settings')}
      />
      <Drawer.Screen name="Profile" component={UserProfileScreen} options={() => defaultScreenOptions('Profile')} />
      <Drawer.Screen name="Gallery" component={GalleryScreen} options={() => defaultScreenOptions('Gallery')} />
      <Drawer.Screen
        name="GalleryCreate"
        component={GalleryCreateScreen}
        options={() => defaultScreenOptions('Create Gallery Item')}
      />
      <Drawer.Screen
        name="GalleryDetail"
        component={GalleryDetailScreen}
        options={() => defaultScreenOptions('Update Gallery Item')}
      />
      <Drawer.Screen name="Feed" component={FeedScreen} options={() => defaultScreenOptions('Feed')} />
      <Drawer.Screen
        name="FeedCreate"
        component={FeedCreateScreen}
        options={() => defaultScreenOptions('Create Feed Item')}
      />
      <Drawer.Screen
        name="FeedDetail"
        component={FeedDetailScreen}
        options={() => defaultScreenOptions('Update Feed Item')}
      />
    </Drawer.Navigator>
  );
}

const AppStack = createStackNavigator();

function AppStackComponent() {
  return (
    <AppStack.Navigator
      initialRouteName={config.initialRouteName}
      screenOptions={{ animationEnabled: false, gestureEnabled: false, presentation: 'modal' }}
    >
      <AppStack.Screen name="Drawer" component={DrawerComponent} options={{ headerShown: false }} />
    </AppStack.Navigator>
  );
}

const RootStack = createStackNavigator();

export const AppNavigation = () => {
  return (
    <RootStack.Navigator initialRouteName="App" screenOptions={{ gestureEnabled: false, headerShown: false }}>
      <RootStack.Screen name="App" component={AppStackComponent} />
    </RootStack.Navigator>
  );
};
