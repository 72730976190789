import { Alert, Linking, Platform } from 'react-native';

export function openMaps(address: string) {
  if (address) {
    let baseUrl = 'http://maps.google.com/maps?daddr=';

    if (Platform.OS === 'ios') {
      baseUrl = 'http://maps.apple.com/maps?daddr=';
    }

    Linking.openURL(`${baseUrl}${encodeURIComponent(address)}`).catch(() => {
      Alert.alert('Maps Error', 'Unable to open address in mapping application.');
    });
  }
}

export function openPhone(phone: string) {
  if (phone) {
    Linking.openURL(`tel://${phone}`).catch(() => {
      Alert.alert('Dialing Error', 'Unable to initiate phone call.');
    });
  }
}

export function openEmail(email: string) {
  if (email) {
    Linking.openURL(`mailto://${email}?subject=Hello`).catch(() => {
      Alert.alert('Email Error', 'Unable to launch email editor.');
    });
  }
}

export const generateShortUUID = () => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let uuid = '';

  for (let i = 0; i < 6; i++) {
    uuid += chars[Math.floor(Math.random() * chars.length)];
  }

  return uuid;
};
