import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GalleryItem } from '../../models/data';
import { galleryService } from '../../services';
import { GalleryState } from './types';

export const getGalleryItems = createAsyncThunk('gallery/getGalleryItems', async () => {
  return galleryService.getGalleryItems();
});

export const getGalleryItem = createAsyncThunk('gallery/getGalleryItem', async (id: string) => {
  return galleryService.getGalleryItem(id);
});

export const createGalleryItem = createAsyncThunk(
  'gallery/createGalleryItem',
  async (gallery: Partial<GalleryItem>) => {
    return galleryService.store(gallery);
  },
);

export const updateGalleryItem = createAsyncThunk(
  'gallery/updateGalleryItem',
  async (gallery: Partial<GalleryItem>) => {
    return galleryService.update(gallery);
  },
);

export const deleteGalleryItem = createAsyncThunk('gallery/deleteGalleryItem', async (gallery: GalleryItem) => {
  return galleryService.delete(gallery);
});

const initialState: GalleryState = {
  currentGalleryItem: null,
  galleryItems: [],
  loading: false,
  error: null,
};

export const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setGalleryItems(state, { payload: { galleryItems } }: PayloadAction<{ galleryItems: GalleryItem[] }>) {
      state.galleryItems = galleryItems;
    },

    setGalleryItem(state, { payload }: PayloadAction<GalleryItem>) {
      state.currentGalleryItem = payload;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGalleryItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGalleryItems.fulfilled, (state, action) => {
        state.loading = false;
        state.galleryItems = action.payload;
      })
      .addCase(getGalleryItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load gallery.';
      });

    builder
      .addCase(getGalleryItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGalleryItem.fulfilled, (state, action) => {
        state.loading = false;
        state.currentGalleryItem = action.payload;
      })
      .addCase(getGalleryItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load gallery.';
      });

    builder
      .addCase(createGalleryItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(createGalleryItem.fulfilled, (state, action) => {
        state.loading = false;
        state.currentGalleryItem = action.payload;
      })
      .addCase(createGalleryItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to create gallery.';
      });

    builder
      .addCase(updateGalleryItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGalleryItem.fulfilled, (state, action) => {
        state.loading = false;
        state.currentGalleryItem = action.payload;
      })
      .addCase(updateGalleryItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to update gallery.';
      });

    builder
      .addCase(deleteGalleryItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteGalleryItem.fulfilled, (state, action) => {
        state.loading = false;
        state.currentGalleryItem = action.payload;
      })
      .addCase(deleteGalleryItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to delete gallery.';
      });
  },
});

export const galleryActions = {
  ...gallerySlice.actions,
  getGalleryItems,
  getGalleryItem,
  createGalleryItem,
  updateGalleryItem,
  deleteGalleryItem,
};

export type GalleryItemsSlice = {
  [gallerySlice.name]: ReturnType<typeof gallerySlice['reducer']>;
};
