import { Box, Button, HStack, Image, Link, ScrollView } from 'native-base';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { galleryActions } from '../../store/actions';
import { SplashScreen } from '../SplashScreen';

export interface GalleryScreenProps {}

export const GalleryScreen: React.FC<GalleryScreenProps> = (props) => {
  const dispatch = useAppDispatch();

  const { loading, galleryItems, error } = useAppSelector((state) => state.gallery);

  useEffect(() => {
    dispatch(galleryActions.getGalleryItems());
  }, []);

  if (loading) {
    return <SplashScreen />;
  }

  return (
    <ScrollView>
      <Box p={4}>
        <Button.Group space={2}>
          <Link href="/gallery/create">
            <Button mb={4} isDisabled={false}>
              Create New Gallery Item
            </Button>
          </Link>
        </Button.Group>

        <HStack flexWrap="wrap" space={4}>
          {galleryItems.map((item) => {
            return (
              <Link
                href={`gallery/${item.id}`}
                key={item.id}
                flexBasis="300px"
                minHeight="300px"
                height="auto"
                mb={4}
                backgroundColor="red"
              >
                <Image source={{ uri: item.url }} alt={item.caption} width="100%" height="100%" resizeMode="cover" />
              </Link>
            );
          })}
        </HStack>
      </Box>
    </ScrollView>
  );
};
