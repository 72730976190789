/* eslint-disable no-console */
import { StoreCreator } from '@reduxjs/toolkit';
import now from 'performance-now';
import config, { ReduxLoggingLevel } from '../../config';

const round = (number: number) => Math.round(number * 100) / 100;

const monitorReducerEnhancer: StoreCreator =
  (next: StoreCreator) => (reducer: any, initialState: any, enhancer: any) => {
    const monitoredReducer = (state: any, action: any) => {
      if (config.reduxLoggingLevel === ReduxLoggingLevel.NONE) {
        return reducer(state, action);
      }

      const start = now();
      const newState = reducer(state, action);
      const end = now();
      const diff = round(end - start);

      console.log(`[STORE] [${action.type}] Action process time:`, diff);

      return newState;
    };

    return next(monitoredReducer, initialState, enhancer);
  };

export default monitorReducerEnhancer;
