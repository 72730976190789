import 'react-native-gesture-handler';

import { APP_ENV, SENTRY_DEBUG, SENTRY_DSN } from '@env';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { NativeBaseProvider } from 'native-base';
import React, { useCallback } from 'react';
import { View } from 'react-native';
import { Provider } from 'react-redux';
import * as Sentry from 'sentry-expo';
import { DialogProvider } from './src/contexts';
import { Nav } from './src/navigation';
import store from './src/store';
import { theme } from './src/styles';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: APP_ENV,
  enableInExpoDevelopment: true,
  debug: SENTRY_DEBUG, // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
});

SplashScreen.preventAutoHideAsync();

export default function App() {
  const [fontsLoaded] = useFonts({});

  const onLayoutRootView = useCallback(async () => {
    console.log('Fonts loaded?', fontsLoaded);

    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <View onLayout={onLayoutRootView} style={{ width: '100%', height: '100%' }}>
      <Provider store={store}>
        <NativeBaseProvider theme={theme}>
          <DialogProvider>
            <Nav />
          </DialogProvider>
        </NativeBaseProvider>
      </Provider>
    </View>
  );
}
