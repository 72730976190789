import { useFormik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Heading,
  HStack,
  Input,
  Link,
  ScrollView,
  Select,
  Stack,
  useMediaQuery,
  VStack,
} from 'native-base';
import React, { useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { useDialog } from '../../contexts';
import {
  Campaign,
  FeedItem,
  FeedItemActionColor,
  FeedItemActionLinkType,
  FeedItemAudience,
  FeedItemLocation,
} from '../../models/data';
import { useAppDispatch, useAppSelector } from '../../store';
import { feedActions } from '../../store/actions';
import { colors } from '../../styles';
import { FeedCampaignSearch } from './FeedCampaignSearch';

const feedItemSchema = Yup.object().shape({
  description: Yup.string(),
  title: Yup.string().required(),
  imageUrl: Yup.string().url('The URL must be valid'),
  actionLinkType: Yup.string().required(),
  actionLink: Yup.string()
    .required()
    .when('actionLinkType', {
      is: (val: FeedItemActionLinkType) => val === FeedItemActionLinkType.URL,
      then: (schema) => schema.url(),
    }),
  actionColor: Yup.string().required(),
  location: Yup.string().required(),
  priority: Yup.number().moreThan(-1).integer().required(),
});

export interface FeedFormProps {
  feedItem?: FeedItem;
  onSubmit: () => void;
  onDelete?: () => void;
}

interface AudienceFormData {
  audienceEveryone: boolean;
  audienceStudents: boolean;
  audienceVolunteers: boolean;
  audienceInactiveStudents: boolean;
  audienceInactiveVolunteers: boolean;
  audienceUnregisteredStudents: boolean;
  audienceUnregisteredVolunteers: boolean;
  audienceStudentCampaigns: Campaign[];
  audienceVolunteerCampaigns: Campaign[];
}

const convertAudienceFormDataToAudience = (audienceFormData: AudienceFormData): FeedItemAudience => {
  return {
    everyone: !!audienceFormData.audienceEveryone,
    students: !!audienceFormData.audienceStudents,
    volunteers: !!audienceFormData.audienceVolunteers,
    inactiveStudents: !!audienceFormData.audienceInactiveStudents,
    inactiveVolunteers: !!audienceFormData.audienceInactiveVolunteers,
    unregisteredStudents: !!audienceFormData.audienceUnregisteredStudents,
    unregisteredVolunteers: !!audienceFormData.audienceUnregisteredVolunteers,
    studentsWithCampaignIds: audienceFormData.audienceStudentCampaigns.map((campaign) => campaign.sfid),
    volunteersWithCampaignIds: audienceFormData.audienceVolunteerCampaigns.map((campaign) => campaign.sfid),
  };
};

export const FeedForm: React.FC<FeedFormProps> = ({ feedItem, onSubmit, onDelete }) => {
  const dispatch = useAppDispatch();
  const { showAlert } = useDialog();

  const [isSmallScreen] = useMediaQuery({
    maxHeight: 480,
  });

  const feedState = useAppSelector((state) => state.feed);
  const { loading: feedItemsLoading, error } = feedState;

  const submit = useCallback(
    async (values: Partial<FeedItem> & AudienceFormData) => {
      const valuesCopy = { ...values };
      const audience = convertAudienceFormDataToAudience(valuesCopy);

      delete valuesCopy.audienceEveryone;
      delete valuesCopy.audienceStudents;
      delete valuesCopy.audienceVolunteers;
      delete valuesCopy.audienceInactiveStudents;
      delete valuesCopy.audienceInactiveVolunteers;
      delete valuesCopy.audienceUnregisteredStudents;
      delete valuesCopy.audienceUnregisteredVolunteers;
      delete valuesCopy.audienceStudentCampaigns;
      delete valuesCopy.audienceVolunteerCampaigns;

      const feedItemData: FeedItem = {
        ...feedItem,
        ...valuesCopy,
        audience,
      };

      let nextFeedItem: FeedItem;

      if (values.id) {
        nextFeedItem = await dispatch(feedActions.updateFeedItem(feedItemData)).unwrap();
      } else {
        nextFeedItem = await dispatch(feedActions.createFeedItem(feedItemData)).unwrap();
      }

      if (nextFeedItem) {
        onSubmit();
      }
    },
    [onSubmit, feedItem],
  );

  const formik = useFormik({
    initialValues: {
      title: feedItem?.title || '',
      location: feedItem?.location || FeedItemLocation.HOME,
      actionLinkType: feedItem?.actionLinkType || FeedItemActionLinkType.URL,
      actionLink: feedItem?.actionLink || '',
      description: feedItem?.description || '',
      imageUrl: feedItem?.imageUrl || '',
      actionText: feedItem?.actionText || 'Read More',
      actionColor: feedItem?.actionColor || FeedItemActionColor.DEFAULT,
      priority: Number.isInteger(feedItem?.priority) ? feedItem.priority : 1,
      audienceEveryone: feedItem?.audience?.everyone ?? true,
      audienceStudents: !!feedItem?.audience?.students,
      audienceVolunteers: !!feedItem?.audience?.volunteers,
      audienceInactiveStudents: !!feedItem?.audience?.inactiveStudents,
      audienceInactiveVolunteers: !!feedItem?.audience?.inactiveVolunteers,
      audienceUnregisteredStudents: !!feedItem?.audience?.unregisteredStudents,
      audienceUnregisteredVolunteers: !!feedItem?.audience?.unregisteredVolunteers,
      audienceStudentCampaigns: [],
      audienceVolunteerCampaigns: [],
    },
    validationSchema: feedItemSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });

  useEffect(() => {
    if (error) {
      dispatch(feedActions.updateError(null));
      showAlert({ title: 'Create failed', message: 'An error occurred. Please try again.' });
    }
  }, [error, showAlert]);

  useEffect(() => {
    if (!feedItem) {
      return;
    }

    formik.setFieldValue('title', feedItem.title);
    formik.setFieldValue('location', feedItem.location);
    formik.setFieldValue('actionLinkType', feedItem.actionLinkType);
    formik.setFieldValue('actionLink', feedItem.actionLink);

    if (feedItem.description) {
      formik.setFieldValue('description', feedItem.description);
    }

    if (feedItem.imageUrl) {
      formik.setFieldValue('imageUrl', feedItem.imageUrl);
    }

    if (feedItem.actionText) {
      formik.setFieldValue('actionText', feedItem.actionText);
    }

    if (feedItem.actionColor) {
      formik.setFieldValue('actionColor', feedItem.actionColor);
    }

    if (feedItem.priority) {
      formik.setFieldValue('priority', feedItem.priority);
    }

    if (feedItem.audience) {
      formik.setFieldValue('audienceEveryone', feedItem.audience.everyone);
      formik.setFieldValue('audienceStudents', feedItem.audience.students);
      formik.setFieldValue('audienceVolunteers', feedItem.audience.volunteers);
      formik.setFieldValue('audienceInactiveStudents', feedItem.audience.inactiveStudents);
      formik.setFieldValue('audienceInactiveVolunteers', feedItem.audience.inactiveVolunteers);
      formik.setFieldValue('audienceUnregisteredStudents', feedItem.audience.unregisteredStudents);
      formik.setFieldValue('audienceUnregisteredVolunteers', feedItem.audience.unregisteredVolunteers);
      formik.setFieldValue('audienceStudentCampaigns', feedItem.audience.studentCampaigns ?? []);
      formik.setFieldValue('audienceVolunteerCampaigns', feedItem.audience.volunteerCampaigns ?? []);
    }
  }, [feedItem]);

  const addCampaign = useCallback(
    (type: 'student' | 'volunteer') => (campaign: Campaign) => {
      switch (type) {
        case 'student':
          formik.setFieldValue('audienceStudentCampaigns', [...formik.values.audienceStudentCampaigns, campaign]);
          break;
        case 'volunteer':
          formik.setFieldValue('audienceVolunteerCampaigns', [...formik.values.audienceVolunteerCampaigns, campaign]);
          break;
      }
    },
    [formik],
  );

  const removeCampaign = useCallback(
    (type: 'student' | 'volunteer') => (campaignToRemove: Campaign) => {
      switch (type) {
        case 'student':
          formik.setFieldValue(
            'audienceStudentCampaigns',
            formik.values.audienceStudentCampaigns.filter((campaign) => {
              return campaign.sfid !== campaignToRemove.sfid;
            }),
          );
          break;
        case 'volunteer':
          formik.setFieldValue(
            'audienceVolunteerCampaigns',
            formik.values.audienceVolunteerCampaigns.filter((campaign) => {
              return campaign.sfid !== campaignToRemove.sfid;
            }),
          );
          break;
      }
    },
    [formik],
  );

  return (
    <ScrollView>
      <Box p={4}>
        <VStack mb={4} space={4}>
          <FormControl isInvalid={formik.touched.location && formik.errors.location ? true : false}>
            <FormControl.Label>
              <Heading size="sm">Location</Heading>
            </FormControl.Label>
            <Select
              selectedValue={formik.values.location}
              onValueChange={formik.handleChange('location')}
              // isDisabled={feedItemsLoading}
              isDisabled
            >
              {Object.values(FeedItemLocation).map((value, index) => {
                return <Select.Item label={value} value={value} key={index} />;
              })}
            </Select>
            <FormControl.ErrorMessage>
              {formik.touched.location && formik.errors.location ? formik.errors.location : null}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl isInvalid={formik.touched.title && formik.errors.title ? true : false}>
            <FormControl.Label>
              <Heading size="sm">Title</Heading>
            </FormControl.Label>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('title')}
              autoCapitalize="words"
              returnKeyType="next"
              value={formik.values.title}
              isDisabled={feedItemsLoading}
            />
            <FormControl.ErrorMessage>
              {formik.touched.title && formik.errors.title ? formik.errors.title : null}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl isInvalid={formik.touched.description && formik.errors.description ? true : false}>
            <FormControl.Label>
              <Heading size="sm">Description</Heading>
            </FormControl.Label>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('description')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.description}
              isDisabled={feedItemsLoading}
            />
            <FormControl.ErrorMessage>
              {formik.touched.description && formik.errors.description ? formik.errors.description : null}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl isInvalid={formik.touched.imageUrl && formik.errors.imageUrl ? true : false}>
            <FormControl.Label>
              <Heading size="sm">Image URL</Heading>
            </FormControl.Label>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('imageUrl')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.imageUrl}
              isDisabled={feedItemsLoading}
            />
            <FormControl.ErrorMessage>
              {formik.touched.imageUrl && formik.errors.imageUrl ? formik.errors.imageUrl : null}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl isInvalid={formik.touched.actionLinkType && formik.errors.actionLinkType ? true : false}>
            <FormControl.Label>
              <Heading size="sm">Action Link Type</Heading>
            </FormControl.Label>
            <Select
              selectedValue={formik.values.actionLinkType}
              onValueChange={formik.handleChange('actionLinkType')}
              isDisabled={feedItemsLoading}
            >
              {Object.values(FeedItemActionLinkType).map((value, index) => {
                return <Select.Item label={value} value={value} key={index} />;
              })}
            </Select>
            <FormControl.ErrorMessage>
              {formik.touched.actionLinkType && formik.errors.actionLinkType ? formik.errors.actionLinkType : null}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl isInvalid={formik.touched.actionLink && formik.errors.actionLink ? true : false}>
            <FormControl.Label>
              <Heading size="sm">Action Link</Heading>
            </FormControl.Label>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('actionLink')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.actionLink}
              isDisabled={feedItemsLoading}
            />
            <FormControl.ErrorMessage>
              {formik.touched.actionLink && formik.errors.actionLink ? formik.errors.actionLink : null}
            </FormControl.ErrorMessage>
            {formik.values.actionLinkType === FeedItemActionLinkType.NAV && (
              <FormControl.HelperText>
                Please consult with Mission Cloud if you'd like to configure in-app navigation
              </FormControl.HelperText>
            )}
          </FormControl>
          <FormControl isInvalid={formik.touched.actionText && formik.errors.actionText ? true : false}>
            <FormControl.Label>
              <Heading size="sm">Action Text</Heading>
            </FormControl.Label>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('actionText')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.actionText}
              isDisabled={feedItemsLoading}
            />
            <FormControl.ErrorMessage>
              {formik.touched.actionText && formik.errors.actionText ? formik.errors.actionText : null}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl isInvalid={formik.touched.actionColor && formik.errors.actionColor ? true : false}>
            <FormControl.Label>
              <Heading size="sm">Action Color</Heading>
            </FormControl.Label>
            <Select
              selectedValue={formik.values.actionColor}
              onValueChange={formik.handleChange('actionColor')}
              isDisabled={feedItemsLoading}
            >
              {Object.values(FeedItemActionColor).map((value, index) => {
                return <Select.Item label={value} value={value} key={index} />;
              })}
            </Select>
            <FormControl.ErrorMessage>
              {formik.touched.actionColor && formik.errors.actionColor ? formik.errors.actionColor : null}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl isInvalid={formik.touched.priority && formik.errors.priority ? true : false}>
            <FormControl.Label>
              <Heading size="sm">Priority</Heading>
            </FormControl.Label>
            <Input
              placeholder=""
              onChangeText={(newValue) => {
                let nextValue = parseInt(newValue);
                if (isNaN(nextValue)) {
                  nextValue = 1;
                }
                formik.setFieldValue('priority', nextValue);
              }}
              autoCapitalize="none"
              keyboardType="numeric"
              returnKeyType="next"
              value={formik.values.priority.toString()}
              isDisabled={feedItemsLoading}
            />
            <FormControl.HelperText>
              Lower numbers are higher priority. 0 is the highest priority.
            </FormControl.HelperText>
            <FormControl.ErrorMessage>
              {formik.touched.priority && formik.errors.priority ? formik.errors.priority : null}
            </FormControl.ErrorMessage>
          </FormControl>

          <Divider my={4} />

          <Heading size="md">Audience</Heading>

          <FormControl>
            <HStack alignItems="center" space={2}>
              <Checkbox
                value="audienceEveryone"
                isChecked={formik.values.audienceEveryone}
                onChange={(checked) => formik.setFieldValue('audienceEveryone', checked)}
                color={formik.values.audienceEveryone ? colors.primary : undefined}
                isDisabled={feedItemsLoading}
              >
                Everyone
              </Checkbox>
            </HStack>
            <FormControl.HelperText>Checking this box will display this feed item to everyone.</FormControl.HelperText>
          </FormControl>

          <FormControl>
            <HStack alignItems="center" space={2}>
              <Checkbox
                value="audienceStudents"
                isChecked={formik.values.audienceStudents}
                onChange={(checked) => formik.setFieldValue('audienceStudents', checked)}
                color={formik.values.audienceStudents ? colors.primary : undefined}
                isDisabled={feedItemsLoading}
              >
                Active Students
              </Checkbox>
            </HStack>
            <FormControl.HelperText>
              Checking this box will display this feed item to all registered students in an active campaign.
            </FormControl.HelperText>
          </FormControl>

          <FormControl>
            <HStack alignItems="center" space={2}>
              <Checkbox
                value="audienceInactiveStudents"
                isChecked={formik.values.audienceInactiveStudents}
                onChange={(checked) => formik.setFieldValue('audienceInactiveStudents', checked)}
                color={formik.values.audienceInactiveStudents ? colors.primary : undefined}
                isDisabled={feedItemsLoading}
              >
                Inactive Students
              </Checkbox>
            </HStack>
            <FormControl.HelperText>
              Checking this box will display this feed item to all registered students in an inactive campaign.
            </FormControl.HelperText>
          </FormControl>

          <FormControl>
            <HStack alignItems="center" space={2}>
              <Checkbox
                value="audienceUnregisteredStudents"
                isChecked={formik.values.audienceUnregisteredStudents}
                onChange={(checked) => formik.setFieldValue('audienceUnregisteredStudents', checked)}
                color={formik.values.audienceUnregisteredStudents ? colors.primary : undefined}
                isDisabled={feedItemsLoading}
              >
                Unregistered Students
              </Checkbox>
            </HStack>
            <FormControl.HelperText>
              Checking this box will display this feed item to all students who have no registration data.
            </FormControl.HelperText>
          </FormControl>

          <FormControl>
            <HStack alignItems="center" space={2}>
              <Checkbox
                value="audienceVolunteers"
                isChecked={formik.values.audienceVolunteers}
                onChange={(checked) => formik.setFieldValue('audienceVolunteers', checked)}
                color={formik.values.audienceVolunteers ? colors.primary : undefined}
                isDisabled={feedItemsLoading}
              >
                Active Volunteers
              </Checkbox>
            </HStack>
            <FormControl.HelperText>
              Checking this box will display this feed item to all registered volunteers in an active campaign.
            </FormControl.HelperText>
          </FormControl>

          <FormControl>
            <HStack alignItems="center" space={2}>
              <Checkbox
                value="audienceInactiveVolunteers"
                isChecked={formik.values.audienceInactiveVolunteers}
                onChange={(checked) => formik.setFieldValue('audienceInactiveVolunteers', checked)}
                color={formik.values.audienceInactiveVolunteers ? colors.primary : undefined}
                isDisabled={feedItemsLoading}
              >
                Inactive Volunteers
              </Checkbox>
            </HStack>
            <FormControl.HelperText>
              Checking this box will display this feed item to all registered volunteers in an inactive campaign.
            </FormControl.HelperText>
          </FormControl>

          <FormControl>
            <HStack alignItems="center" space={2}>
              <Checkbox
                value="audienceUnregisteredVolunteers"
                isChecked={formik.values.audienceUnregisteredVolunteers}
                onChange={(checked) => formik.setFieldValue('audienceUnregisteredVolunteers', checked)}
                color={formik.values.audienceUnregisteredVolunteers ? colors.primary : undefined}
                isDisabled={feedItemsLoading}
              >
                Unregistered Volunteers
              </Checkbox>
            </HStack>
            <FormControl.HelperText>
              Checking this box will display this feed item to all volunteers who have no registration data.
            </FormControl.HelperText>
          </FormControl>

          <Stack flexDir={isSmallScreen ? 'column' : 'row'} space={4}>
            <FeedCampaignSearch
              label="Student Campaigns"
              helpText="Show this feed item to registered students in the selected campaigns. Users will only see this if their active campaign is selected."
              formCampaigns={formik.values.audienceStudentCampaigns}
              onAddCampaign={addCampaign('student')}
              onRemoveCampaign={removeCampaign('student')}
              error={formik.errors.audienceStudentCampaigns}
              disabled={feedItemsLoading}
              editable
            />
            <FeedCampaignSearch
              label="Volunteer Campaigns"
              helpText="Show this feed item to registered volunteers in the selected campaigns. Users will only see this if their active campaign is selected."
              formCampaigns={formik.values.audienceVolunteerCampaigns}
              onAddCampaign={addCampaign('volunteer')}
              onRemoveCampaign={removeCampaign('volunteer')}
              error={formik.errors.audienceVolunteerCampaigns}
              disabled={feedItemsLoading}
              editable
            />
          </Stack>
        </VStack>

        <Divider my={4} />

        <Button.Group space={4} justifyContent="flex-end">
          {feedItem && (
            <Button onPress={onDelete} colorScheme="danger" variant="subtle" isDisabled={feedItemsLoading}>
              DELETE
            </Button>
          )}
          <Link href="/feed">
            <Button colorScheme="secondary" variant="subtle" isDisabled={feedItemsLoading}>
              CANCEL
            </Button>
          </Link>
          <Button onPress={() => formik.handleSubmit()} colorScheme="primary" isDisabled={feedItemsLoading}>
            SAVE
          </Button>
        </Button.Group>
      </Box>
    </ScrollView>
  );
};
