import React, { useCallback } from 'react';
import { GalleryForm } from '../../components/gallery';

export const GalleryCreateScreen: React.FC<any> = (props) => {
  const onSubmit = useCallback(() => {
    window.location.href = '/gallery';
  }, []);

  return <GalleryForm onSubmit={onSubmit} />;
};
