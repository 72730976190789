import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { UnauthorizedScreen } from '../screens';

const UnauthorizedStack = createStackNavigator();

export const UnauthorizedNavigation = () => {
  return (
    <UnauthorizedStack.Navigator
      initialRouteName="Unauthorized"
      screenOptions={{ gestureEnabled: false, headerShown: false }}
    >
      <UnauthorizedStack.Screen name="Unauthorized" component={UnauthorizedScreen} options={{ headerShown: false }} />
    </UnauthorizedStack.Navigator>
  );
};
