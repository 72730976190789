import { useFormik } from 'formik';
import { Box, Button, Checkbox, Divider, FormControl, HStack, Input, Link, ScrollView, VStack } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useDialog } from '../../contexts';
import { Campaign } from '../../models/data';
import { useAppDispatch, useAppSelector } from '../../store';
import { campaignsActions } from '../../store/actions';
import { colors } from '../../styles';
import { SplashScreen } from '../SplashScreen';

const campaignSettingsSchema = Yup.object().shape({
  automatedNotificationsDisabled: Yup.boolean(),
});

export const CampaignDetailScreen: React.FC<any> = ({ navigation, route }) => {
  const dispatch = useAppDispatch();
  const { showAlert } = useDialog();

  const { id } = route.params;

  const campaignState = useAppSelector((state) => state.campaigns);
  const { loading, error, currentCampaign } = campaignState;

  const [initialLoading, setInitialLoading] = useState(true);

  const formik = useFormik({
    initialValues: {
      automatedNotificationsDisabled: !!currentCampaign?.automatedNotificationsDisabled,
    },
    validationSchema: campaignSettingsSchema,
    onSubmit: (values) => {
      submitUpdate(values);
    },
  });

  useEffect(() => {
    dispatch(campaignsActions.getCampaign(id));
  }, []);

  useEffect(() => {
    if (!currentCampaign) {
      return;
    }

    setInitialLoading(false);

    formik.setFieldValue('automatedNotificationsDisabled', !!currentCampaign.automatedNotificationsDisabled);
  }, [currentCampaign]);

  useEffect(() => {
    if (error) {
      dispatch(campaignsActions.updateError(null));
      showAlert({ title: 'Error', message: 'Campaign could not be updated.  Please try again.' });
    }
  }, [error]);

  const submitUpdate = useCallback(
    async (values: Partial<Campaign>) => {
      const update = {
        ...currentCampaign,
        automatedNotificationsDisabled: values.automatedNotificationsDisabled,
      };

      const updatedCampaign = await dispatch(campaignsActions.updateCampaign(update));
      if (updatedCampaign) {
        await showAlert({ title: 'Success', message: 'Campaign has been updated.' });
      }
    },
    [currentCampaign],
  );

  if (initialLoading || !currentCampaign) {
    return <SplashScreen />;
  }

  return (
    <ScrollView>
      <Box p={4}>
        <VStack space={4}>
          <FormControl>
            <FormControl.Label>Salesforce ID (SFID)</FormControl.Label>
            <Input value={currentCampaign.sfid} isDisabled />
          </FormControl>
          <FormControl>
            <FormControl.Label>Name</FormControl.Label>
            <Input value={currentCampaign.name} isDisabled />
          </FormControl>

          <FormControl>
            <HStack alignItems="center" space={2}>
              <Checkbox
                value="automatedNotificationsDisabled"
                isChecked={formik.values.automatedNotificationsDisabled}
                onChange={(checked) => formik.setFieldValue('automatedNotificationsDisabled', checked)}
                color={formik.values.automatedNotificationsDisabled ? colors.primary : undefined}
                isDisabled={loading}
              >
                DISABLE AUTOMATED NOTIFICATIONS
              </Checkbox>
            </HStack>
            <FormControl.HelperText>
              Checking this box will disable all automated notifications for all users in this campaign.
            </FormControl.HelperText>
          </FormControl>
        </VStack>

        <Divider my={4} />

        <Button.Group space={4} justifyContent="flex-end">
          <Link href="/campaigns">
            <Button colorScheme="secondary" variant="subtle" isDisabled={loading}>
              CANCEL
            </Button>
          </Link>
          <Button onPress={() => formik.handleSubmit()} colorScheme="primary" isDisabled={loading} isLoading={loading}>
            SAVE
          </Button>
        </Button.Group>
      </Box>
    </ScrollView>
  );
};
