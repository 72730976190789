import { useFormik } from 'formik';
import { Box, Button, Checkbox, Divider, FormControl, HStack, Input, Link, ScrollView, VStack } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useDialog } from '../../contexts';
import { Role } from '../../models/data';
import { useAppDispatch, useAppSelector } from '../../store';
import { rolesActions } from '../../store/actions';
import { colors } from '../../styles';
import { SplashScreen } from '../SplashScreen';

const roleSettingsSchema = Yup.object().shape({
  automatedNotificationsDisabled: Yup.boolean(),
});

export const RoleDetailScreen: React.FC<any> = ({ navigation, route }) => {
  const dispatch = useAppDispatch();
  const { showAlert } = useDialog();

  const { id } = route.params;

  const roleState = useAppSelector((state) => state.roles);
  const { loading, error, currentRole } = roleState;

  const [initialLoading, setInitialLoading] = useState(true);

  const formik = useFormik({
    initialValues: {
      automatedNotificationsDisabled: !!currentRole?.automatedNotificationsDisabled,
    },
    validationSchema: roleSettingsSchema,
    onSubmit: (values) => {
      submitUpdate(values);
    },
  });

  useEffect(() => {
    dispatch(rolesActions.getRole(id));
  }, []);

  useEffect(() => {
    if (!currentRole) {
      return;
    }

    setInitialLoading(false);

    formik.setFieldValue('automatedNotificationsDisabled', !!currentRole.automatedNotificationsDisabled);
  }, [currentRole]);

  useEffect(() => {
    if (error) {
      dispatch(rolesActions.updateError(null));
      showAlert({ title: 'Error', message: 'Role could not be updated.  Please try again.' });
    }
  }, [error]);

  const submitUpdate = useCallback(
    async (values: Partial<Role>) => {
      const update = {
        ...currentRole,
        automatedNotificationsDisabled: values.automatedNotificationsDisabled,
      };

      const updatedRole = await dispatch(rolesActions.updateRole(update)).unwrap();
      if (updatedRole) {
        await showAlert({ title: 'Success', message: 'Role has been updated.' });
      }
    },
    [currentRole],
  );

  if (initialLoading || !currentRole) {
    return <SplashScreen />;
  }

  return (
    <ScrollView>
      <Box p={4}>
        <VStack space={4}>
          <FormControl>
            <FormControl.Label>Role ID</FormControl.Label>
            <Input value={currentRole.id} isDisabled />
          </FormControl>

          <FormControl>
            <FormControl.Label>Name</FormControl.Label>
            <Input value={currentRole.name} isDisabled />
          </FormControl>

          <FormControl>
            <HStack alignItems="center" space={2}>
              <Checkbox
                value="automatedNotificationsDisabled"
                isChecked={formik.values.automatedNotificationsDisabled}
                onChange={(checked) => formik.setFieldValue('automatedNotificationsDisabled', checked)}
                color={formik.values.automatedNotificationsDisabled ? colors.primary : undefined}
                isDisabled={loading}
              >
                DISABLE AUTOMATED NOTIFICATIONS
              </Checkbox>
            </HStack>
            <FormControl.HelperText>
              Checking this box will disable all automated notifications for all users with this role.
            </FormControl.HelperText>
          </FormControl>
        </VStack>

        <Divider my={4} />

        <Button.Group space={4} justifyContent="flex-end">
          <Link href="/roles">
            <Button colorScheme="secondary" variant="subtle" isDisabled={loading}>
              CANCEL
            </Button>
          </Link>
          <Button onPress={() => formik.handleSubmit()} colorScheme="primary" isDisabled={loading} isLoading={loading}>
            SAVE
          </Button>
        </Button.Group>
      </Box>
    </ScrollView>
  );
};
