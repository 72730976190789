import moment from 'moment-timezone';
import { Box, Heading, ScrollView, Text } from 'native-base';
import React, { useEffect } from 'react';
import { DataTable } from 'react-native-paper';
import { SplashScreen } from '../screens';
import { useAppDispatch, useAppSelector } from '../store';
import { analyticsActions } from '../store/actions';
import { rootStyles } from '../styles';

export const DashboardScreen: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const analyticsState = useAppSelector((state) => state.analytics);
  const { loading, currentAnalytics } = analyticsState;

  useEffect(() => {
    dispatch(analyticsActions.getAnalytics());
  }, []);

  if (loading || !currentAnalytics) {
    return <SplashScreen />;
  }

  return (
    <ScrollView>
      <Box p={4}>
        <Heading style={rootStyles.centeredText}>
          System Status: {currentAnalytics.systemStatus ? currentAnalytics.systemStatus.toUpperCase() : ''}
        </Heading>
        <DataTable>
          <DataTable.Row>
            <DataTable.Cell>Total Users</DataTable.Cell>
            <DataTable.Cell numeric>{currentAnalytics.userCount}</DataTable.Cell>
          </DataTable.Row>
          <DataTable.Row>
            <DataTable.Cell>Unregistered Users</DataTable.Cell>
            <DataTable.Cell numeric>{currentAnalytics.unregisteredUserCount}</DataTable.Cell>
          </DataTable.Row>
          <DataTable.Row>
            <DataTable.Cell>Pending Student Registrations</DataTable.Cell>
            <DataTable.Cell numeric>{currentAnalytics.pendingStudentRegistrationCount}</DataTable.Cell>
          </DataTable.Row>
          <DataTable.Row>
            <DataTable.Cell>Pending Volunteer Registrations</DataTable.Cell>
            <DataTable.Cell numeric>{currentAnalytics.pendingVolunteerRegistrationCount}</DataTable.Cell>
          </DataTable.Row>
          <DataTable.Row>
            <DataTable.Cell>Failed Student Registrations</DataTable.Cell>
            <DataTable.Cell numeric>{currentAnalytics.failedStudentRegistrationCount}</DataTable.Cell>
          </DataTable.Row>
          <DataTable.Row>
            <DataTable.Cell>Failed Volunteer Registrations</DataTable.Cell>
            <DataTable.Cell numeric>{currentAnalytics.failedVolunteerRegistrationCount}</DataTable.Cell>
          </DataTable.Row>
          <DataTable.Row>
            <DataTable.Cell>Complete Student Registrations</DataTable.Cell>
            <DataTable.Cell numeric>{currentAnalytics.completeStudentRegistrationCount}</DataTable.Cell>
          </DataTable.Row>
          <DataTable.Row>
            <DataTable.Cell>Complete Volunteer Registrations</DataTable.Cell>
            <DataTable.Cell numeric>{currentAnalytics.completeVolunteerRegistrationCount}</DataTable.Cell>
          </DataTable.Row>
        </DataTable>
        <hr />

        <Heading size="sm" style={rootStyles.centeredText}>
          PENDING REGISTRATIONS
        </Heading>
        {!currentAnalytics.pendingRegistrations?.length ? (
          <Text>None</Text>
        ) : (
          <DataTable>
            {currentAnalytics.pendingRegistrations?.map((registration, index) => {
              return (
                <DataTable.Row key={index}>
                  <DataTable.Cell>{moment(registration.createdAt).format('MMM D YYYY, h:mm a')}</DataTable.Cell>
                  <DataTable.Cell>
                    {registration.firstName} {registration.lastName}
                  </DataTable.Cell>
                  <DataTable.Cell>{registration.email}</DataTable.Cell>
                </DataTable.Row>
              );
            })}
          </DataTable>
        )}
        <hr />

        <Heading size="sm" style={rootStyles.centeredText}>
          FAILED REGISTRATIONS
        </Heading>
        {!currentAnalytics.failedRegistrations?.length ? (
          <Text>None</Text>
        ) : (
          <DataTable>
            {currentAnalytics.failedRegistrations?.map((registration, index) => {
              return (
                <DataTable.Row key={index}>
                  <DataTable.Cell>{moment(registration.createdAt).format('MMM D YYYY, h:mm a')}</DataTable.Cell>
                  <DataTable.Cell>
                    {registration.firstName} {registration.lastName}
                  </DataTable.Cell>
                  <DataTable.Cell>{registration.email}</DataTable.Cell>
                </DataTable.Row>
              );
            })}
          </DataTable>
        )}
        <hr />

        <Heading size="sm" style={rootStyles.centeredText}>
          UNREGISTERED USERS
        </Heading>
        {!currentAnalytics.unregisteredUsers?.length ? (
          <Text>None</Text>
        ) : (
          <DataTable>
            {currentAnalytics.unregisteredUsers?.map((user, index) => {
              return (
                <DataTable.Row key={index}>
                  <DataTable.Cell>{moment(user.createdAt).format('MMM D YYYY, h:mm a')}</DataTable.Cell>
                  <DataTable.Cell>
                    {user.firstName} {user.lastName}
                  </DataTable.Cell>
                  <DataTable.Cell>{user.email}</DataTable.Cell>
                </DataTable.Row>
              );
            })}
          </DataTable>
        )}
      </Box>
    </ScrollView>
  );
};
