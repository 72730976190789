import React, { useCallback } from 'react';
import { ResourcesForm } from '../../components/resources';

export const ResourceCreateScreen: React.FC<any> = () => {
  const onSubmit = useCallback(() => {
    window.location.href = '/resources';
  }, []);

  return <ResourcesForm onSubmit={onSubmit} />;
};
