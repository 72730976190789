import { Feather } from '@expo/vector-icons';
import { Box, Button, Divider, HStack, Link, ScrollView, Text, VStack } from 'native-base';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { resourcesActions } from '../../store/actions';
import { colors } from '../../styles';
import { SplashScreen } from '../SplashScreen';

export const ResourcesScreen: React.FC<any> = ({ navigation }) => {
  const dispatch = useAppDispatch();

  const resourceState = useAppSelector((state) => state.resources);
  const { loading: resourcesLoading, resources } = resourceState;

  useEffect(() => {
    dispatch(resourcesActions.getResources(undefined));
  }, []);

  if (resourcesLoading) {
    return <SplashScreen />;
  }

  return (
    <ScrollView>
      <VStack p={4}>
        <Button.Group space={2}>
          <Link href="/resources/create">
            <Button mb={4} isDisabled={resourcesLoading}>
              Create New Resource
            </Button>
          </Link>
        </Button.Group>

        <VStack space={4}>
          {resources.map((resource) => {
            return (
              <Link href={`resources/${resource.id}`} key={resource.id} width="100%">
                <VStack width="100%" space={4}>
                  <HStack alignItems="center" justifyContent="space-between" width="100%">
                    <Box>
                      <Text>{resource.name}</Text>
                    </Box>
                    <Box>
                      <Feather name="chevron-right" size={25} style={{ color: colors.primary }} />
                    </Box>
                  </HStack>
                  <Divider />
                </VStack>
              </Link>
            );
          })}
        </VStack>
      </VStack>
    </ScrollView>
  );
};
