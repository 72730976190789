import { Role } from '../models/data';
import BaseService from './BaseService';

export class RoleService extends BaseService {
  async getRoles() {
    return (await this.getJson<Role[]>('/roles')).data;
  }

  async getRole(id: string) {
    return (await this.getJson<Role>('/roles/' + id)).data;
  }

  async update(role: Role) {
    return (await this.putJson<Role>('/roles/' + role.id, role)).data;
  }
}

export default new RoleService();
