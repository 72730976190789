import { parseISO } from 'date-fns';
import { useFormik } from 'formik';
import moment from 'moment-timezone';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  HStack,
  Input,
  Link,
  Radio,
  ScrollView,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from 'native-base';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Yup from 'yup';
import { NotificationStatus } from '../../constants';
import { useDialog } from '../../contexts';
import { Campaign, Notification, User } from '../../models/data';
import { useAppDispatch, useAppSelector } from '../../store';
import { notificationsActions } from '../../store/actions';
import { colors } from '../../styles';
import { CampaignSearch } from './CampaignSearch';
import { UserSearch } from './UserSearch';

const notificationSchema = Yup.object().shape({
  link: Yup.string().url('The URL must be valid'),
  subject: Yup.string().required('Required'),
  message: Yup.string().required('Required'),
  campaigns: Yup.array(),
  users: Yup.array().required('Required'),
});

interface TimeButtonProps {
  value?: string;
  onClick?: () => void;
  isDisabled?: boolean;
}

const TimeButton: React.FC<TimeButtonProps> = forwardRef(({ value, onClick, isDisabled }, ref) => {
  return (
    <Button onPress={() => onClick && onClick()} isDisabled={isDisabled}>
      {value || 'Select Date & Time'}
    </Button>
  );
});

export interface NotificationFormProps {
  notification?: Notification;
  onSubmit: () => void;
  onDelete?: () => void;
}

export const NotificationForm: React.FC<NotificationFormProps> = ({ notification, onSubmit, onDelete }) => {
  const dispatch = useAppDispatch();

  const { showAlert } = useDialog();

  const [isSmallScreen] = useMediaQuery({
    maxHeight: 480,
  });

  const notificationState = useAppSelector((state) => state.notifications);
  const { loading: notificationsLoading, error } = notificationState;

  const [isEditable, setIsEditable] = useState(false);

  const SEND_NOW = 'now';
  const SEND_LATER = 'later';

  const formik = useFormik({
    initialValues: {
      subject: notification?.subject || '',
      message: notification?.message || '',
      urgency: notification?.urgency || false,
      link: notification?.link || '',
      campaigns: [],
      users: [],
      sendAt: notification?.sendAt ? parseISO(notification?.sendAt) : new Date(),
      sendSelection: notification?.sendAt ? SEND_LATER : SEND_NOW,
    },
    validationSchema: notificationSchema,
    onSubmit: (values) => {
      submit(values);
    },
  });

  useEffect(() => {
    if (error) {
      dispatch(notificationsActions.updateError(null));
      showAlert({ title: 'Error occurred', message: 'An error occurred. Please try again.' });
    }
  }, [error]);

  useEffect(() => {
    if (!notification) {
      setIsEditable(true);
      return;
    }

    setIsEditable(notification.status === NotificationStatus.STATUS_SCHEDULED);

    formik.setFieldValue('subject', notification.subject);
    formik.setFieldValue('message', notification.message);
    formik.setFieldValue('urgency', notification.urgency);
    formik.setFieldValue('link', notification.link);
    formik.setFieldValue('sendAt', notification?.sendAt ? parseISO(notification?.sendAt) : new Date());
    formik.setFieldValue('sendSelection', notification?.sendAt ? SEND_LATER : SEND_NOW);
    formik.setFieldValue('users', notification.users ?? []);
    formik.setFieldValue('campaigns', notification.campaigns ?? []);
  }, [notification]);

  const filterPassedTime = useCallback((time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  }, []);

  const submit = useCallback(
    async (values: typeof formik.initialValues) => {
      const campaignIds = values.campaigns.map((campaign: Campaign) => {
        return campaign.sfid;
      });

      const userIds = values.users.map((user: User) => {
        return user.id;
      });

      const sendAt = moment(values.sendSelection === SEND_NOW ? moment() : values.sendAt)
        .tz('America/Chicago')
        .format();

      const notificationData = { ...notification, ...values, campaignIds, userIds, sendAt };
      delete notificationData.campaigns;
      delete notificationData.users;

      let nextNotification: Notification;

      if (notificationData.id) {
        nextNotification = await dispatch(notificationsActions.updateNotification(notificationData)).unwrap();
      } else {
        nextNotification = await dispatch(notificationsActions.createNotification(notificationData)).unwrap();
      }

      if (nextNotification) {
        onSubmit();
      }
    },
    [onSubmit, notification],
  );

  const addUser = useCallback(
    (user: User) => {
      formik.setFieldValue('users', [...formik.values.users, user]);
    },
    [formik],
  );

  const addCampaign = useCallback(
    (campaign: Campaign) => {
      formik.setFieldValue('campaigns', [...formik.values.campaigns, campaign]);
    },
    [formik],
  );

  const removeUser = useCallback(
    (userToRemove: User) => {
      formik.setFieldValue(
        'users',
        formik.values.users.filter((user: User) => {
          return user.id !== userToRemove.id;
        }),
      );
    },
    [formik],
  );

  const removeCampaign = useCallback(
    (campaignToRemove: Campaign) => {
      formik.setFieldValue(
        'campaigns',
        formik.values.campaigns.filter((campaign: Campaign) => {
          return campaign.sfid !== campaignToRemove.sfid;
        }),
      );
    },
    [formik],
  );

  return (
    <ScrollView>
      <Box p={4}>
        <VStack space={4}>
          <FormControl isInvalid={formik.touched.subject && formik.errors.subject ? true : false}>
            <FormControl.Label>Subject</FormControl.Label>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('subject')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.subject}
              isDisabled={notificationsLoading || !isEditable}
            />
            <FormControl.ErrorMessage>
              {formik.touched.subject && formik.errors.subject ? formik.errors.subject : null}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl isInvalid={formik.touched.message && formik.errors.message ? true : false}>
            <FormControl.Label>Message</FormControl.Label>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('message')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.message}
              isDisabled={notificationsLoading || !isEditable}
            />
            <FormControl.ErrorMessage>
              {formik.touched.message && formik.errors.message ? formik.errors.message : null}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl isInvalid={formik.touched.link && formik.errors.link ? true : false}>
            <FormControl.Label>URL</FormControl.Label>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('link')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.link}
              isDisabled={notificationsLoading || !isEditable}
            />
            <FormControl.ErrorMessage>
              {formik.touched.link && formik.errors.link ? formik.errors.link : null}
            </FormControl.ErrorMessage>
          </FormControl>

          <FormControl>
            <HStack alignItems="center" space={2}>
              <Checkbox
                value="urgent"
                onChange={(checked) => formik.setFieldValue('urgency', checked)}
                color={formik.values.urgency ? colors.primary : undefined}
                isDisabled={notificationsLoading || !isEditable}
              >
                Mark As Urgent
              </Checkbox>
            </HStack>
            <FormControl.HelperText>
              Checking this box will display the notification as urgent for app users.
            </FormControl.HelperText>
          </FormControl>

          <Radio.Group
            name="sendSelection"
            accessibilityLabel="Send Selection"
            value={formik.values.sendSelection}
            onChange={(nextValue) => formik.setFieldValue('sendSelection', nextValue)}
          >
            <HStack alignItems="center" justifyContent="space-between" space={4}>
              <HStack space={2}>
                <Radio value={SEND_NOW} isDisabled={notificationsLoading || !isEditable}>
                  Send Immediately
                </Radio>
              </HStack>
              <HStack space={2} alignItems="center">
                <Radio value={SEND_LATER} isDisabled={notificationsLoading || !isEditable}>
                  Send At Scheduled Date &amp; Time
                </Radio>
              </HStack>
            </HStack>
          </Radio.Group>

          {formik.values.sendSelection === SEND_LATER && (
            <>
              <Text>Timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone}</Text>
              <DatePicker
                selected={formik.values.sendAt}
                onChange={(value) => formik.setFieldValue('sendAt', value)}
                showTimeSelect
                filterTime={filterPassedTime}
                dateFormat="MMMM d, yyyy h:mm aa"
                customInput={<TimeButton isDisabled={notificationsLoading || !isEditable} />}
              />
            </>
          )}

          <Stack flexDir={isSmallScreen ? 'column' : 'row'} space={4}>
            <UserSearch
              formUsers={formik.values.users}
              onAddUser={addUser}
              onRemoveUser={removeUser}
              error={formik.errors.users}
              disabled={notificationsLoading}
              editable={isEditable}
            />

            <CampaignSearch
              formCampaigns={formik.values.campaigns}
              onAddCampaign={addCampaign}
              onRemoveCampaign={removeCampaign}
              error={formik.errors.campaigns}
              disabled={notificationsLoading}
              editable={isEditable}
            />
          </Stack>
        </VStack>

        <Divider my={4} />

        <Button.Group space={4} justifyContent="flex-end">
          {isEditable && notification && (
            <Button
              onPress={onDelete}
              colorScheme="danger"
              variant="subtle"
              isDisabled={notificationsLoading || !isEditable}
            >
              DELETE
            </Button>
          )}
          <Link href="/notifications">
            <Button colorScheme="secondary" variant="subtle" isDisabled={notificationsLoading}>
              CANCEL
            </Button>
          </Link>
          <Button
            onPress={() => formik.handleSubmit()}
            colorScheme="primary"
            isDisabled={notificationsLoading || !isEditable}
          >
            SAVE
          </Button>
        </Button.Group>
      </Box>
    </ScrollView>
  );
};
