import { useFormik } from 'formik';
import { Box, Button, Divider, FormControl, Heading, Input, Link, Select, Text, VStack } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import * as Yup from 'yup';
import { useDialog } from '../../contexts';
import { useAppDispatch, useAppSelector } from '../../store';
import { settingsActions } from '../../store/actions';
import { SplashScreen } from '../SplashScreen';

const selectFieldSlugs = {
  api_mode: {
    options: ['maintenance', 'production'],
  },
  automated_day_of_notification: {
    options: ['on', 'off'],
  },
  automated_day_before_notification: {
    options: ['on', 'off'],
  },
};

const settingProfileSchema = Yup.object().shape({
  value: Yup.string().required('Required'),
});

export const SettingDetailScreen: React.FC<any> = ({ navigation, route }) => {
  const dispatch = useAppDispatch();
  const { showAlert } = useDialog();

  const { id } = route.params;

  const settingState = useAppSelector((state) => state.settings);
  const { currentSetting, loading: settingsLoading, error } = settingState;

  const [initialLoading, setInitialLoading] = useState(true);

  const formik = useFormik({
    initialValues: {
      value: currentSetting?.value || '',
    },
    validationSchema: settingProfileSchema,
    onSubmit: (values) => {
      submitUpdate(values.value);
    },
  });

  useEffect(() => {
    const loadData = async () => {
      await dispatch(settingsActions.getSetting(id)).unwrap();
      setInitialLoading(false);
    };

    loadData();
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(settingsActions.updateError(null));
      showAlert({ title: 'Update failed', message: 'An error occurred. Please try again.' });
    }
  }, [error]);

  useEffect(() => {
    if (!currentSetting) {
      return;
    }

    formik.setFieldValue('value', currentSetting.value);
  }, [currentSetting]);

  const submitUpdate = useCallback(
    async (nextValue: string) => {
      const update = {
        ...currentSetting,
        value: nextValue,
      };

      const updatedSetting = await dispatch(settingsActions.updateSetting(update)).unwrap();
      if (updatedSetting) {
        dispatch(settingsActions.getSettings(undefined));
        await showAlert({ title: 'Success', message: 'Setting has been updated.' });
      }
    },
    [currentSetting],
  );

  if (initialLoading || !currentSetting) {
    return <SplashScreen />;
  }

  return (
    <ScrollView>
      <Box p={4}>
        <VStack>
          <Heading>{currentSetting.name}</Heading>
          <Heading size="sm" mb={4}>
            {currentSetting.description}
          </Heading>
          <FormControl isInvalid={formik.touched.value && formik.errors.value ? true : false}>
            <FormControl.Label>Value</FormControl.Label>
            <FormControl.ErrorMessage>
              {formik.touched.value && formik.errors.value ? formik.errors.value : null}
            </FormControl.ErrorMessage>
            {selectFieldSlugs[currentSetting.slug] ? (
              <Select
                style={{ width: '100%' }}
                selectedValue={formik.values.value}
                onValueChange={formik.handleChange('value')}
              >
                {selectFieldSlugs[currentSetting.slug].options.map((option, index) => {
                  return <Select.Item label={option} value={option} key={index} />;
                })}
              </Select>
            ) : (
              <Input
                placeholder=""
                onChangeText={formik.handleChange('value')}
                autoCapitalize="none"
                returnKeyType="next"
                value={formik.values.value}
              />
            )}
          </FormControl>

          <Divider my={4} />

          <Button.Group space={4} justifyContent="flex-end">
            <Link href="/settings">
              <Button colorScheme="secondary" variant="subtle" isDisabled={settingsLoading}>
                CANCEL
              </Button>
            </Link>
            <Button
              onPress={() => formik.handleSubmit()}
              colorScheme="success"
              isDisabled={settingsLoading}
              isLoading={settingsLoading}
            >
              <Text>SAVE</Text>
            </Button>
          </Button.Group>
        </VStack>
      </Box>
    </ScrollView>
  );
};
