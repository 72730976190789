import { Feather } from '@expo/vector-icons';
import { Badge, Box, Button, Divider, HStack, Link, ScrollView, Text, VStack } from 'native-base';
import React, { useEffect } from 'react';
import { FeedItemActionLinkType } from '../../models/data';
import { useAppDispatch, useAppSelector } from '../../store';
import { feedActions } from '../../store/actions';
import { colors } from '../../styles';
import { SplashScreen } from '../SplashScreen';

export const FeedScreen: React.FC<any> = (props) => {
  const dispatch = useAppDispatch();

  const { loading, feedItems, error } = useAppSelector((state) => state.feed);

  useEffect(() => {
    dispatch(feedActions.getFeedItems());
  }, []);

  if (loading) {
    return <SplashScreen />;
  }

  return (
    <ScrollView>
      <Box p={4}>
        <Button.Group space={2}>
          <Link href="/feed/create">
            <Button mb={4} isDisabled={false}>
              Create New Feed Item
            </Button>
          </Link>
        </Button.Group>

        <VStack space={4}>
          {feedItems.map((item) => {
            return (
              <Link href={`feed/${item.id}`} key={item.id} width="100%">
                <VStack width="100%" space={4}>
                  <HStack alignItems="center" justifyContent="space-between" width="100%">
                    <VStack space={1} alignItems="flex-start" justifyContent="flex-start">
                      <HStack space={2} alignItems="center">
                        <Text fontWeight="bold" fontSize="md">
                          {item.title}
                        </Text>
                        {item.imageUrl && <Feather name="image" size={24} color={colors.secondary} />}
                        {item.actionLinkType === FeedItemActionLinkType.NAV && (
                          <Feather name="link" size={24} color={colors.secondary} />
                        )}
                        {item.actionLinkType === FeedItemActionLinkType.URL && (
                          <Feather name="external-link" size={24} color={colors.secondary} />
                        )}
                      </HStack>
                      <HStack space={2} width="100%">
                        <Badge>
                          <Text fontSize="12">Location: {item.location}</Text>
                        </Badge>
                        <Badge>
                          <Text fontSize="12">Priority: {item.priority}</Text>
                        </Badge>
                        {item.audience.everyone && <Badge>Everyone</Badge>}
                        {item.audience.students && <Badge>Active Students</Badge>}
                        {item.audience.inactiveStudents && <Badge>Inactive Students</Badge>}
                        {item.audience.unregisteredStudents && <Badge>Unregistered Students</Badge>}
                        {item.audience.studentsWithCampaignIds?.length > 0 && (
                          <Badge flexDir="row">
                            <Text fontSize="12">
                              {item.audience.studentsWithCampaignIds?.length} Students Campaign(s)
                            </Text>
                          </Badge>
                        )}
                        {item.audience.volunteers && <Badge>Volunteers</Badge>}
                        {item.audience.inactiveVolunteers && <Badge>Inactive Volunteers</Badge>}
                        {item.audience.unregisteredVolunteers && <Badge>Unregistered Volunteers</Badge>}
                        {item.audience.volunteersWithCampaignIds?.length > 0 && (
                          <Badge>
                            <Text fontSize="12">
                              {item.audience.volunteersWithCampaignIds?.length} Volunteers Campaign(s)
                            </Text>
                          </Badge>
                        )}
                      </HStack>
                    </VStack>
                    <Box>
                      <Feather name="chevron-right" size={25} style={{ color: colors.primary }} />
                    </Box>
                  </HStack>
                  <Divider />
                </VStack>
              </Link>
            );
          })}
        </VStack>
      </Box>
    </ScrollView>
  );
};
