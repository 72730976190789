import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from '../../models/data';
import { roleService } from '../../services';
import { RoleState } from './types';

export const getRoles = createAsyncThunk('roles/getRoles', async () => {
  return roleService.getRoles();
});

export const getRole = createAsyncThunk('roles/getRole', async (id: string) => {
  return roleService.getRole(id);
});

export const updateRole = createAsyncThunk('roles/updateRole', async (role: Role) => {
  return roleService.update(role);
});

const initialState: RoleState = {
  currentRole: null,
  roles: [],
  loading: false,
  error: null,
};

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setRoles(state, { payload: { roles } }: PayloadAction<{ roles: Role[] }>) {
      state.roles = roles;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.roles = action.payload;
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load roles.';
      });

    builder
      .addCase(getRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRole.fulfilled, (state, action) => {
        state.loading = false;
        state.currentRole = action.payload;
      })
      .addCase(getRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load role.';
      });

    builder
      .addCase(updateRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.loading = false;
        state.currentRole = action.payload;
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to update role.';
      });
  },
});

export const rolesActions = {
  ...rolesSlice.actions,
  getRoles,
  getRole,
  updateRole,
};

export type RolesSlice = {
  [rolesSlice.name]: ReturnType<typeof rolesSlice['reducer']>;
};
