import { Campaign } from './Campaign';

export enum FeedItemLocation {
  HOME = 'HOME',
}

export enum FeedItemActionLinkType {
  URL = 'URL',
  NAV = 'NAV',
}

export enum FeedItemActionColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DEFAULT = 'default',
  DANGER = 'danger',
}

export interface FeedItemAudience {
  everyone?: boolean;
  students?: boolean;
  volunteers?: boolean;
  inactiveStudents?: boolean;
  inactiveVolunteers?: boolean;
  unregisteredStudents?: boolean;
  unregisteredVolunteers?: boolean;
  studentsWithCampaignIds?: string[];
  volunteersWithCampaignIds?: string[];
  studentCampaigns?: Campaign[];
  volunteerCampaigns?: Campaign[];
}

export interface FeedItem {
  id: string;
  title: string;
  location: FeedItemLocation;
  actionLinkType: FeedItemActionLinkType;
  actionLink: string;
  description?: string;
  imageUrl?: string;
  actionText?: string;
  actionColor?: FeedItemActionColor;
  created_at: string;
  updated_at: string;
  priority: number;
  audience: FeedItemAudience;
}
