import { User } from '../models/data';
import BaseService from './BaseService';

export interface UserPaginationResponse {
  users: User[];
  userCount: number;
  pageCount: number;
  page: number;
  perPage: number;
}

export class UserService extends BaseService {
  async getSelf() {
    return (await this.getJson<User>('/users/me')).data;
  }

  async updateSelf(user: User) {
    return (await this.putJson<User>('/users/me', user)).data;
  }

  async getUser(id: string) {
    return (await this.getJson<User>('/users/' + id)).data;
  }

  async getUsers(page?: number, query?: string) {
    const currentPage: number = page || 1;

    let params = {};

    if (page) {
      params['page'] = currentPage;
    }

    if (query) {
      params['query'] = query;
    }

    return (
      await this.getJson<UserPaginationResponse>('/users', {
        params,
      })
    ).data;
  }

  async update(user: User) {
    return (await this.putJson<User>('/users/' + user.id, user)).data;
  }
}

export default new UserService();
