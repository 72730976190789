import { MaterialIcons } from '@expo/vector-icons';
import { Box, Button, Divider, Icon, Link, ScrollView, Text, VStack } from 'native-base';
import React, { PropsWithChildren, useCallback } from 'react';
import { useDialog } from '../contexts';
import { useAppDispatch, useAppSelector } from '../store';
import { authActions } from '../store/actions';
import { colors } from '../styles';

interface DrawerItemProps extends PropsWithChildren {
  icon?: string;
  onPress?: () => void;
  href?: string;
  label?: string;
}

const DrawerItem: React.FC<DrawerItemProps> = ({ icon, label, href, onPress, children }) => {
  const isHovered = window.location.href.includes(href);

  return (
    <Link href={href}>
      <Button
        w="100%"
        variant="ghost"
        onPress={onPress}
        leftIcon={icon && <Icon as={MaterialIcons} name={icon} color={colors.secondary} size="lg" />}
        justifyContent="flex-start"
        colorScheme="secondary"
        isHovered={isHovered}
      >
        {children ? (
          children
        ) : (
          <Text fontSize="md" ml={1}>
            {label}
          </Text>
        )}
      </Button>
    </Link>
  );
};

export default function DrawerContent(props: any) {
  const dispatch = useAppDispatch();
  const { showConfirm } = useDialog();

  const signOut = useCallback(async () => {
    if (await showConfirm({ title: 'Sign Out', message: 'Are you sure you want to sign out?' })) {
      dispatch(authActions.signOutUser(true));
    }
  }, [dispatch]);

  const currentUser = useAppSelector((state) => state.auth.currentUser);

  return (
    <ScrollView {...props} style={{ minHeight: '100vh' }}>
      <VStack style={{ minHeight: '100vh' }} justifyContent="space-between">
        <VStack m={2} space={2}>
          <DrawerItem icon="dashboard" label="Dashboard" href="/dashboard" />
          <DrawerItem icon="notifications" label="Notifications" href="/notifications" />
          <DrawerItem icon="library-books" label="Resources" href="/resources" />
          <DrawerItem icon="campaign" label="Campaigns" href="/campaigns" />
          <DrawerItem icon="dynamic-feed" label="Feed" href="/feed" />
          <DrawerItem icon="photo-album" label="Gallery" href="/gallery" />
          <DrawerItem icon="supervised-user-circle" label="Users" href="/users" />
          <DrawerItem icon="shield" label="Roles" href="/roles" />
        </VStack>
        <VStack m={2} space={1}>
          <Divider />
          <DrawerItem href="/profile">
            <Box ml={1}>
              <Text fontWeight="bold" fontSize="md" isTruncated maxWidth="100%">
                {currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : ''}
              </Text>
              <Text isTruncated maxWidth="100%">
                {currentUser ? currentUser.email : ''}
              </Text>
            </Box>
          </DrawerItem>
          <DrawerItem icon="logout" label="Sign Out" onPress={() => signOut()} />
        </VStack>
      </VStack>
    </ScrollView>
  );
}
