import { Feather } from '@expo/vector-icons';
import { Box, Divider, Heading, HStack, Link, ScrollView, Text, VStack } from 'native-base';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { rolesActions } from '../../store/actions';
import { colors } from '../../styles';
import { SplashScreen } from '../SplashScreen';

export const RolesScreen: React.FC<any> = ({ navigation }) => {
  const dispatch = useAppDispatch();

  const roleState = useAppSelector((state) => state.roles);
  const { loading: rolesLoading, roles } = roleState;

  useEffect(() => {
    dispatch(rolesActions.getRoles());
  }, []);

  if (rolesLoading) {
    return <SplashScreen />;
  }

  return (
    <ScrollView>
      <Box p={4}>
        {roles.length > 0 ? (
          <VStack space={4}>
            {roles.map((role) => {
              return (
                <Link href={`roles/${role.id}`} key={role.id} width="100%">
                  <VStack width="100%" space={4}>
                    <HStack alignItems="center" justifyContent="space-between" width="100%">
                      <Box>
                        <Text style={{ color: colors.secondary }}>
                          {role.name} (ID: {role.id})
                        </Text>
                      </Box>
                      <Box>
                        <Feather name="chevron-right" size={25} style={{ color: colors.primary }} />
                      </Box>
                    </HStack>
                    <Divider />
                  </VStack>
                </Link>
              );
            })}
          </VStack>
        ) : (
          <Heading size="md" style={{ alignSelf: 'center', marginTop: '20px' }}>
            No roles found.
          </Heading>
        )}
      </Box>
    </ScrollView>
  );
};
