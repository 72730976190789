import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Resource } from '../../models/data';
import { resourceService } from '../../services';
import { ResourceState } from './types';

export const getResources = createAsyncThunk('resources/getResources', async (query?: string) => {
  return resourceService.getResources(query);
});

export const getResource = createAsyncThunk('resources/getResource', async (id: string) => {
  return resourceService.getResource(id);
});

export const createResource = createAsyncThunk('resources/createResource', async (resource: Resource) => {
  return resourceService.store(resource);
});

export const updateResource = createAsyncThunk('resources/updateResource', async (resource: Resource) => {
  return resourceService.update(resource);
});

export const deleteResource = createAsyncThunk('resources/deleteResource', async (resource: Resource) => {
  return resourceService.delete(resource);
});

const initialState: ResourceState = {
  currentResource: null,
  resources: [],
  loading: false,
  error: null,
  currentPage: 1,
  pageCount: null,
};

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setResources(state, { payload: { resources } }: PayloadAction<{ resources: Resource[] }>) {
      state.resources = resources;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResources.pending, (state) => {
        state.loading = true;
      })
      .addCase(getResources.fulfilled, (state, action) => {
        state.loading = false;
        state.resources = action.payload;
      })
      .addCase(getResources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load resources.';
      });

    builder
      .addCase(getResource.pending, (state) => {
        state.loading = true;
      })
      .addCase(getResource.fulfilled, (state, action) => {
        state.loading = false;
        state.currentResource = action.payload;
      })
      .addCase(getResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load resource.';
      });

    builder
      .addCase(createResource.pending, (state) => {
        state.loading = true;
      })
      .addCase(createResource.fulfilled, (state, action) => {
        state.loading = false;
        state.currentResource = action.payload;
      })
      .addCase(createResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to create resource.';
      });

    builder
      .addCase(updateResource.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateResource.fulfilled, (state, action) => {
        state.loading = false;
        state.currentResource = action.payload;
      })
      .addCase(updateResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to update resource.';
      });

    builder
      .addCase(deleteResource.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteResource.fulfilled, (state, action) => {
        state.loading = false;
        state.currentResource = null;
      })
      .addCase(deleteResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to delete resource.';
      });
  },
});

export const resourcesActions = {
  ...resourcesSlice.actions,
  getResources,
  getResource,
  createResource,
  updateResource,
  deleteResource,
};

export type ResourcesSlice = {
  [resourcesSlice.name]: ReturnType<typeof resourcesSlice['reducer']>;
};
