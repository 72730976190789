import { useCallback } from 'react';
import { RoleNames } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../store';

export const useDataLoader = () => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);

  const preAuthLoad = useCallback(async () => {
    const actionsToDispatch: any[] = [];

    await Promise.all(actionsToDispatch.map((action) => dispatch(action)));
  }, [dispatch]);

  const load = useCallback(async () => {
    const actionsToDispatch: any[] = [];

    if (currentUser?.role) {
      if ([RoleNames.STUDENT, RoleNames.VOLUNTEER].includes(currentUser.role.name as RoleNames)) {
        actionsToDispatch.push();
      }

      if (currentUser.role.name === RoleNames.STUDENT) {
        actionsToDispatch.push();
      }

      if (currentUser.role.name === RoleNames.VOLUNTEER) {
        actionsToDispatch.push();
      }
    }

    await Promise.all(actionsToDispatch.map((action) => dispatch(action)));
  }, [currentUser]);

  return { load, preAuthLoad };
};
