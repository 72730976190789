import { MaterialIcons } from '@expo/vector-icons';
import { useFormik } from 'formik';
import moment from 'moment-timezone';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Heading,
  HStack,
  Icon,
  Input,
  Link,
  Radio,
  ScrollView,
  Text,
  VStack,
} from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { RoleNames } from '../../constants';
import { useDialog } from '../../contexts';
import { User } from '../../models/data';
import { useAppDispatch, useAppSelector } from '../../store';
import { usersActions } from '../../store/actions';
import { colors } from '../../styles';
import { SplashScreen } from '../SplashScreen';

const userProfileSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  contactSfid: Yup.string(),
  active: Yup.boolean(),
});

export const UserDetailScreen: React.FC<any> = ({ navigation, route }) => {
  const dispatch = useAppDispatch();
  const { showAlert } = useDialog();

  const { id } = route.params;

  const currentUser: User = useAppSelector((state) => state.auth.currentUser);
  const userState = useAppSelector((state) => state.users);
  const { loading, error, activeUser } = userState;

  const [initialLoading, setInitialLoading] = useState(true);

  const formik = useFormik({
    initialValues: {
      firstName: activeUser?.firstName || '',
      lastName: activeUser?.lastName || '',
      active: activeUser?.active || false,
      contactSfid: activeUser?.contact?.sfid || '',
      role: activeUser?.role || { id: undefined, name: '', automatedNotificationsDisabled: false },
      acceptContactChangeMessage: false,
      automatedNotificationsDisabled: activeUser?.automatedNotificationsDisabled || false,
    },
    validationSchema: userProfileSchema,
    onSubmit: (values) => {
      submitUpdate(values);
    },
  });

  useEffect(() => {
    dispatch(usersActions.getUser(id));
  }, []);

  useEffect(() => {
    if (!activeUser) {
      return;
    }

    setInitialLoading(false);

    formik.setFieldValue('firstName', activeUser.firstName);
    formik.setFieldValue('lastName', activeUser.lastName);
    formik.setFieldValue('active', activeUser.active);
    formik.setFieldValue('contactSfid', activeUser.contact?.sfid);
    formik.setFieldValue('role', activeUser.role);
    formik.setFieldValue('automatedNotificationsDisabled', activeUser.automatedNotificationsDisabled);
  }, [activeUser]);

  useEffect(() => {
    if (error) {
      dispatch(usersActions.updateError(null));
      showAlert({ title: 'Error', message: 'User could not be updated.  Please try again.' });
    }
  }, [error]);

  const isCurrentUser = currentUser.id === activeUser?.id;

  const submitUpdate = useCallback(
    async (values: Partial<User> & { acceptContactChangeMessage: boolean }) => {
      const update = {
        ...activeUser,
        firstName: values.firstName,
        lastName: values.lastName,
        contactSfid: values.contactSfid,
        automatedNotificationsDisabled: values.automatedNotificationsDisabled,
      };

      if (values.acceptContactChangeMessage) {
        update.contactSfid = values.contactSfid;
      }

      if (isCurrentUser === false) {
        update.active = values.active;
        update.role = values.role;
      }

      const updatedUser = await dispatch(usersActions.updateUser(update)).unwrap();
      if (updatedUser) {
        await showAlert({ title: 'Success', message: 'User has been updated.' });
      }
    },
    [activeUser, isCurrentUser],
  );

  if (initialLoading || !activeUser) {
    return <SplashScreen />;
  }

  return (
    <ScrollView>
      <Box p={4}>
        {isCurrentUser ? <Text mb={4}>NOTE: You cannot change your own active status or role.</Text> : null}

        <VStack space={4}>
          <FormControl>
            <FormControl.Label>Email</FormControl.Label>
            <Input value={activeUser?.email} isDisabled />
          </FormControl>

          <FormControl isInvalid={formik.touched.firstName && formik.errors.firstName ? true : false}>
            <FormControl.Label>First Name</FormControl.Label>
            <FormControl.ErrorMessage>
              {formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
            </FormControl.ErrorMessage>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('firstName')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.firstName}
              isDisabled={loading}
            />
          </FormControl>

          <FormControl isInvalid={formik.touched.lastName && formik.errors.lastName ? true : false}>
            <FormControl.Label>Last Name</FormControl.Label>
            <FormControl.ErrorMessage>
              {formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null}
            </FormControl.ErrorMessage>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('lastName')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.lastName}
              isDisabled={loading}
            />
          </FormControl>

          <FormControl isInvalid={formik.touched.contactSfid && formik.errors.contactSfid ? true : false}>
            <FormControl.Label>
              Contact Salesforce ID (SFID){' '}
              {formik.values.acceptContactChangeMessage ? (
                <Icon
                  size="md"
                  as={MaterialIcons}
                  name="lock-open"
                  onPress={() => formik.setFieldValue('acceptContactChangeMessage', false)}
                />
              ) : (
                <Icon
                  size="md"
                  as={MaterialIcons}
                  name="lock-outline"
                  onPress={() => formik.setFieldValue('acceptContactChangeMessage', true)}
                />
              )}
            </FormControl.Label>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('contactSfid')}
              autoCapitalize="none"
              returnKeyType="next"
              value={formik.values.contactSfid}
              isDisabled={!formik.values.acceptContactChangeMessage || loading}
            />
            <FormControl.HelperText>
              Changes to this field will alter the user's connection to campaigns and/or accounts. Setting a valid
              Contact Salesforce ID will also complete registration for any failed or pending registration. Click the
              lock to enable the field.
            </FormControl.HelperText>
            <FormControl.ErrorMessage>
              {formik.touched.contactSfid && formik.errors.contactSfid ? formik.errors.contactSfid : null}
            </FormControl.ErrorMessage>
          </FormControl>

          <FormControl>
            <HStack alignItems="center" space={2}>
              <Checkbox
                value="active"
                isChecked={formik.values.active}
                onChange={(checked) => formik.setFieldValue('active', checked)}
                color={formik.values.active ? colors.primary : undefined}
                isDisabled={loading}
              >
                USER IS ACTIVE
              </Checkbox>
            </HStack>
            <FormControl.HelperText>
              Unchecking this box will prevent the user from logging into the RTSWS app.
            </FormControl.HelperText>
          </FormControl>

          <FormControl>
            <HStack alignItems="center" space={2}>
              <Checkbox
                value="automatedNotificationsDisabled"
                isChecked={formik.values.automatedNotificationsDisabled}
                onChange={(checked) => formik.setFieldValue('automatedNotificationsDisabled', checked)}
                color={formik.values.automatedNotificationsDisabled ? colors.primary : undefined}
                isDisabled={loading}
              >
                DISABLE AUTOMATED NOTIFICATIONS
              </Checkbox>
            </HStack>
            <FormControl.HelperText>
              Checking this box will disable all automated notifications for this user.
            </FormControl.HelperText>
          </FormControl>

          <Radio.Group
            name="role"
            accessibilityLabel="User Role"
            value={formik.values.role.name}
            onChange={(newValue) => formik.setFieldValue('role', { name: newValue })}
          >
            <Heading size="sm" mb={4}>
              USER ROLE
            </Heading>
            <VStack justifyContent="space-between" space={2}>
              <Radio value={RoleNames.ADMIN} isDisabled={isCurrentUser || loading}>
                ADMIN
              </Radio>
              <Radio value={RoleNames.STUDENT} isDisabled={isCurrentUser || loading}>
                STUDENT
              </Radio>
              <Radio value={RoleNames.VOLUNTEER} isDisabled={isCurrentUser || loading}>
                VOLUNTEER
              </Radio>
              <Radio value={RoleNames.UNREGISTERED} isDisabled={isCurrentUser || loading}>
                UNREGISTERED
              </Radio>
            </VStack>
          </Radio.Group>

          {activeUser && activeUser.registration && (
            <Box borderWidth={1} borderRadius="md" p={4} borderColor="gray.200">
              <VStack space={4}>
                <Heading size="sm" mb={4}>
                  USER REGISTRATION DATA
                </Heading>
                <FormControl>
                  <FormControl.Label>Type</FormControl.Label>
                  <Text>{activeUser.registration.type ? activeUser.registration.type.toUpperCase() : ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Status</FormControl.Label>
                  <Text>{activeUser.registration.status ? activeUser.registration.status.toUpperCase() : ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Submitted At</FormControl.Label>
                  <Text>{moment(activeUser.registration.createdAt).format('MMMM D, YYYY h:mm A') || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Registration Contact ID</FormControl.Label>
                  <Text>{activeUser.registrationContact?.id || 'None'}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Registration Contact - Heroku Connect Last Operation</FormControl.Label>
                  <Text>{activeUser.registrationContact?.herokuConnectLastOperation || 'None'}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Registration Contact - Heroku Connect Error</FormControl.Label>
                  <Text fontFamily="mono">{activeUser.registrationContact?.herokuConnectError || 'None'}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>First Name</FormControl.Label>
                  <Text>{activeUser.registration.firstName || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Last Name</FormControl.Label>
                  <Text>{activeUser.registration.lastName || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Email</FormControl.Label>
                  <Text>{activeUser.registration.email || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Alternate Email</FormControl.Label>
                  <Text>{activeUser.registration.alternateEmail || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Mobile Phone</FormControl.Label>
                  <Text>{activeUser.registration.mobilePhone || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Address Line 1</FormControl.Label>
                  <Text>{activeUser.registration.addressLine1 || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Address Line 2</FormControl.Label>
                  <Text>{activeUser.registration.addressLine2 || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Address City</FormControl.Label>
                  <Text>{activeUser.registration.addressCity || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Address State</FormControl.Label>
                  <Text>{activeUser.registration.addressState || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Address Zip</FormControl.Label>
                  <Text>{activeUser.registration.addressZip || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Date Of Birth</FormControl.Label>
                  <Text>{activeUser.registration.dateOfBirth || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Graduation Year</FormControl.Label>
                  <Text>{activeUser.registration.graduationYear || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>schoolAccountSfid</FormControl.Label>
                  <Text>{activeUser.registration.schoolAccountSfid || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Gender Identification</FormControl.Label>
                  <Text>{activeUser.registration.genderIdentification || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Race</FormControl.Label>
                  <Text>{activeUser.registration.race || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Company/Firm</FormControl.Label>
                  <Text>{activeUser.registration.company || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Parent First Name</FormControl.Label>
                  <Text>{activeUser.registration.parentFirstName || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Parent Last Name</FormControl.Label>
                  <Text>{activeUser.registration.parentLastName || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Parent Email</FormControl.Label>
                  <Text>{activeUser.registration.parentEmail || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Parent Mobile Phone</FormControl.Label>
                  <Text>{activeUser.registration.parentMobilePhone || ''}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Parent Acknowledged?</FormControl.Label>
                  <Text>{activeUser.registration.parentAcknowledged ? 'true' : 'false'}</Text>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Parent Acknowledged At</FormControl.Label>
                  <Text>
                    {activeUser.registration.parentAcknowledgementTimestamp
                      ? moment
                          .unix(Math.round(activeUser.registration.parentAcknowledgementTimestamp / 1000))
                          .format('MMMM D, YYYY h:mm A')
                      : ''}
                  </Text>
                </FormControl>
              </VStack>
            </Box>
          )}
        </VStack>

        <Divider my={4} />

        <Button.Group space={4} justifyContent="flex-end">
          <Link href="/users">
            <Button colorScheme="secondary" variant="subtle" isDisabled={loading}>
              CANCEL
            </Button>
          </Link>
          <Button onPress={() => formik.handleSubmit()} colorScheme="primary" isDisabled={loading} isLoading={loading}>
            SAVE
          </Button>
        </Button.Group>
      </Box>
    </ScrollView>
  );
};
