import { createStackNavigator } from '@react-navigation/stack';
import * as WebBrowser from 'expo-web-browser';
import React, { useEffect } from 'react';
import { LoginScreen, SplashScreen } from '../screens';

function RedirectScreen() {
  useEffect(() => {
    WebBrowser.maybeCompleteAuthSession();
  }, []);

  return <SplashScreen />;
}

const AuthStack = createStackNavigator();

function AuthStackComponent() {
  return (
    <AuthStack.Navigator initialRouteName="Login" defaultScreenOptions={{ headerShown: false }}>
      <AuthStack.Screen name="Login" component={LoginScreen} options={{ headerShown: false }} />
    </AuthStack.Navigator>
  );
}

const RootStack = createStackNavigator();

export const AuthNavigation = () => {
  return (
    <RootStack.Navigator initialRouteName="Auth" screenOptions={{ gestureEnabled: false, headerShown: false }}>
      <RootStack.Screen name="Redirect" component={RedirectScreen} options={{ headerShown: false }} />
      <RootStack.Screen name="Auth" component={AuthStackComponent} options={{ headerShown: false }} />
    </RootStack.Navigator>
  );
};
