import { useFormik } from 'formik';
import { AddIcon, Box, Button, FormControl, Heading, Input, MinusIcon, useMediaQuery, VStack } from 'native-base';
import React, { useCallback, useMemo } from 'react';
import { Campaign } from '../../models/data';
import { useAppDispatch, useAppSelector } from '../../store';
import { campaignsActions } from '../../store/actions';
import { searchSchema } from './common';

export interface CampaignSearchProps {
  formCampaigns: Campaign[];
  onAddCampaign: (campaign: Campaign) => void;
  onRemoveCampaign: (campaign: Campaign) => void;
  error?: any;
  editable?: boolean;
  disabled?: boolean;
}

export const CampaignSearch: React.FC<CampaignSearchProps> = ({
  formCampaigns,
  error,
  disabled,
  editable,
  onAddCampaign,
  onRemoveCampaign,
}) => {
  const dispatch = useAppDispatch();

  const [isSmallScreen] = useMediaQuery({
    maxHeight: 480,
  });

  const campaignState = useAppSelector((state) => state.campaigns);
  const { loading: campaignsLoading, campaigns } = campaignState;

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    validationSchema: searchSchema,
    onSubmit: (values) => {
      searchCampaigns(values.search);
    },
  });

  const filteredCampaigns = useMemo(() => {
    return campaigns.filter((campaign) => {
      return !formCampaigns
        .map((campaign) => {
          return campaign.sfid;
        })
        .includes(campaign.sfid);
    });
  }, [formCampaigns, campaigns]);

  const searchCampaigns = useCallback((query: string) => {
    dispatch(campaignsActions.getCampaigns(query));
  }, []);

  const addCampaign = useCallback(
    (campaign: Campaign) => () => {
      onAddCampaign(campaign);
    },
    [onAddCampaign],
  );

  const removeCampaign = useCallback(
    (campaign: Campaign) => () => {
      onRemoveCampaign(campaign);
    },
    [onRemoveCampaign],
  );

  return (
    <FormControl ml={isSmallScreen ? 0 : 2} isInvalid={!!error} flex={1}>
      <FormControl.Label>
        <Heading size="sm">To (Campaigns)</Heading>
      </FormControl.Label>
      <FormControl.ErrorMessage>{error}</FormControl.ErrorMessage>
      <Box>
        <FormControl isInvalid={formik.touched.search && formik.errors.search ? true : false}>
          <FormControl.ErrorMessage>
            {formik.touched.search && formik.errors.search ? formik.errors.search : null}
          </FormControl.ErrorMessage>
          <Input
            mb={2}
            placeholder="Search campaigns..."
            onChangeText={formik.handleChange('search')}
            autoCapitalize="none"
            returnKeyType="default"
            value={formik.values.search}
            onSubmitEditing={() => formik.handleSubmit()}
            isDisabled={campaignsLoading || disabled || !editable}
            InputRightElement={
              <Button
                onPress={() => formik.handleSubmit()}
                colorScheme="secondary"
                isDisabled={campaignsLoading || disabled || !editable}
                isLoading={campaignsLoading}
              >
                SEARCH
              </Button>
            }
          />
        </FormControl>
        <VStack space={2} mb={formCampaigns.length > 0 ? 2 : 0}>
          {formCampaigns.length > 0 &&
            formCampaigns.map((campaign) => {
              return (
                <Button
                  key={campaign.sfid}
                  borderRadius={4}
                  size="sm"
                  onPress={removeCampaign(campaign)}
                  leftIcon={<MinusIcon />}
                  colorScheme="secondary"
                  isDisabled={campaignsLoading || disabled || !editable}
                  justifyContent="flex-start"
                >
                  {campaign.name}
                </Button>
              );
            })}
        </VStack>
        <VStack space={2}>
          {filteredCampaigns.map((campaign) => {
            return (
              <Button
                key={campaign.sfid}
                borderRadius={4}
                size="sm"
                onPress={addCampaign(campaign)}
                isDisabled={campaignsLoading || disabled || !editable}
                colorScheme="secondary"
                variant="outline"
                leftIcon={<AddIcon />}
                justifyContent="flex-start"
              >
                {campaign.name}
              </Button>
            );
          })}
        </VStack>
      </Box>
    </FormControl>
  );
};
