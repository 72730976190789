import { Resource } from '../models/data';
import BaseService from './BaseService';

export class ResourceService extends BaseService {
  async getResources(query?: string) {
    let params = {};

    if (query) {
      params['query'] = query;
    }

    return (
      await this.getJson<Resource[]>('/resources', {
        params,
      })
    ).data;
  }

  async getResource(id: string) {
    return (await this.getJson<Resource>('/resources/' + id)).data;
  }

  async store(resource: Resource) {
    return (await this.postJson<Resource>('/resources', resource)).data;
  }

  async update(resource: Resource) {
    return (await this.putJson<Resource>('/resources/' + resource.id, resource)).data;
  }

  async delete(resource: Resource) {
    return (await this.deleteJson<Resource>('/resources/' + resource.id)).data;
  }
}

export default new ResourceService();
