import React, { useCallback, useEffect, useState } from 'react';
import { GalleryForm } from '../../components/gallery';
import { useDialog } from '../../contexts';
import { useAppDispatch, useAppSelector } from '../../store';
import { galleryActions } from '../../store/actions';
import { SplashScreen } from '../SplashScreen';

export const GalleryDetailScreen: React.FC<any> = ({ navigation, route }) => {
  const dispatch = useAppDispatch();
  const { id } = route.params;

  const { showAlert, showConfirm } = useDialog();

  const galleryState = useAppSelector((state) => state.gallery);
  const { currentGalleryItem } = galleryState;

  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      await dispatch(galleryActions.getGalleryItem(id)).unwrap();
      setInitialLoading(false);
    };

    loadData();
  }, []);

  const onSubmit = useCallback(async () => {
    await showAlert({ title: 'Success', message: 'Item has been updated.' });
  }, []);

  const onDelete = useCallback(async () => {
    const confirmation = await showConfirm({ title: 'Delete Item', message: 'Are you sure?' });

    if (confirmation) {
      await dispatch(galleryActions.deleteGalleryItem(currentGalleryItem)).unwrap();
      window.location.href = '/gallery';
    }
  }, [currentGalleryItem]);

  if (initialLoading) {
    return <SplashScreen />;
  }

  return <GalleryForm galleryItem={currentGalleryItem} onSubmit={onSubmit} onDelete={onDelete} />;
};
