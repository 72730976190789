import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeedItem } from '../../models/data';
import { feedService } from '../../services';
import { FeedState } from './types';

export const getFeedItems = createAsyncThunk('feed/getFeedItems', async () => {
  return feedService.getFeedItems();
});

export const getFeedItem = createAsyncThunk('feed/getFeedItem', async (id: string) => {
  return feedService.getFeedItem(id);
});

export const createFeedItem = createAsyncThunk('feed/createFeedItem', async (feed: Partial<FeedItem>) => {
  return feedService.store(feed);
});

export const updateFeedItem = createAsyncThunk('feed/updateFeedItem', async (feed: Partial<FeedItem>) => {
  return feedService.update(feed);
});

export const deleteFeedItem = createAsyncThunk('feed/deleteFeedItem', async (feed: FeedItem) => {
  return feedService.delete(feed);
});

const initialState: FeedState = {
  currentFeedItem: null,
  feedItems: [],
  loading: false,
  error: null,
};

export const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setFeedItems(state, { payload: { feedItems } }: PayloadAction<{ feedItems: FeedItem[] }>) {
      state.feedItems = feedItems;
    },

    setFeedItem(state, { payload }: PayloadAction<FeedItem>) {
      state.currentFeedItem = payload;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeedItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFeedItems.fulfilled, (state, action) => {
        state.loading = false;
        state.feedItems = action.payload;
      })
      .addCase(getFeedItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load feed.';
      });

    builder
      .addCase(getFeedItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFeedItem.fulfilled, (state, action) => {
        state.loading = false;
        state.currentFeedItem = action.payload;
      })
      .addCase(getFeedItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load feed.';
      });

    builder
      .addCase(createFeedItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(createFeedItem.fulfilled, (state, action) => {
        state.loading = false;
        state.currentFeedItem = action.payload;
      })
      .addCase(createFeedItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to create feed.';
      });

    builder
      .addCase(updateFeedItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFeedItem.fulfilled, (state, action) => {
        state.loading = false;
        state.currentFeedItem = action.payload;
      })
      .addCase(updateFeedItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to update feed.';
      });

    builder
      .addCase(deleteFeedItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteFeedItem.fulfilled, (state, action) => {
        state.loading = false;
        state.currentFeedItem = action.payload;
      })
      .addCase(deleteFeedItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to delete feed.';
      });
  },
});

export const feedActions = {
  ...feedSlice.actions,
  getFeedItems,
  getFeedItem,
  createFeedItem,
  updateFeedItem,
  deleteFeedItem,
};

export type FeedItemsSlice = {
  [feedSlice.name]: ReturnType<typeof feedSlice['reducer']>;
};
