import { FeedItem } from '../models/data';
import BaseService from './BaseService';

export class FeedService extends BaseService {
  async getFeedItems() {
    return (await this.getJson<FeedItem[]>('/feed')).data;
  }

  async getFeedItem(id: string) {
    return (await this.getJson<FeedItem>('/feed/' + id)).data;
  }

  async store(feedItem: Partial<FeedItem>) {
    return (await this.postJson<FeedItem>('/feed', feedItem)).data;
  }

  async update(feedItem: Partial<FeedItem>) {
    return (await this.putJson<FeedItem>('/feed/' + feedItem.id, feedItem)).data;
  }

  async delete(feedItem: FeedItem) {
    return (await this.deleteJson<FeedItem>('/feed/' + feedItem.id)).data;
  }
}

export default new FeedService();
