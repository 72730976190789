import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from '../../models/data';
import { notificationService } from '../../services';
import { NotificationState } from './types';

export const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async ({ page, status }: { page?: number; status?: string }, thunkAPI) => {
    thunkAPI.dispatch(notificationsActions.updateCurrentPage(page));
    return notificationService.getNotifications(page, status);
  },
);

export const getNotification = createAsyncThunk('notifications/getNotification', async (id: string) => {
  return notificationService.getNotification(id);
});

export const createNotification = createAsyncThunk(
  'notifications/createNotification',
  async (notification: Partial<Notification> & { campaignIds: string[]; userIds: string[] }) => {
    return notificationService.store(notification);
  },
);

export const updateNotification = createAsyncThunk(
  'notifications/updateNotification',
  async (notification: Partial<Notification> & { campaignIds: string[]; userIds: string[] }) => {
    return notificationService.update(notification);
  },
);

export const deleteNotification = createAsyncThunk(
  'notifications/deleteNotification',
  async (notification: Notification) => {
    return notificationService.delete(notification);
  },
);

const initialState: NotificationState = {
  currentNotification: null,
  notifications: [],
  loading: false,
  error: null,
  currentPage: 1,
  pageCount: null,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    updateCurrentPage(state, { payload }: PayloadAction<number>) {
      state.currentPage = payload;
    },

    setNotifications(state, { payload: { notifications } }: PayloadAction<{ notifications: Notification[] }>) {
      state.notifications = notifications;
    },

    setNotification(state, { payload }: PayloadAction<Notification>) {
      state.currentNotification = payload;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload.notifications;
        state.pageCount = action.payload.pageCount;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load notifications.';
      });

    builder
      .addCase(getNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.currentNotification = action.payload;
      })
      .addCase(getNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load notification.';
      });

    builder
      .addCase(createNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.currentNotification = action.payload;
      })
      .addCase(createNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to create notification.';
      });

    builder
      .addCase(updateNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.currentNotification = action.payload;
      })
      .addCase(updateNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to update notification.';
      });

    builder
      .addCase(deleteNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.currentNotification = action.payload;
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to delete notification.';
      });
  },
});

export const notificationsActions = {
  ...notificationsSlice.actions,
  getNotifications,
  getNotification,
  createNotification,
  updateNotification,
  deleteNotification,
};

export type NotificationsSlice = {
  [notificationsSlice.name]: ReturnType<typeof notificationsSlice['reducer']>;
};
