export enum ReduxLoggingLevel {
  VERBOSE, // timing and state updates
  INFO, // timing only
  NONE,
}

const initialRouteName = 'DashboardStack';
const reduxLoggingLevel = ReduxLoggingLevel.INFO;

export default {
  initialRouteName,
  reduxLoggingLevel,
};
