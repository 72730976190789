import { Feather } from '@expo/vector-icons';
import { useFormik } from 'formik';
import { Box, Button, Center, Divider, FormControl, HStack, Input, Link, ScrollView, Text, VStack } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../store';
import { usersActions } from '../../store/actions';
import { colors, rootStyles } from '../../styles';
import { SplashScreen } from '../SplashScreen';

const searchSchema = Yup.object().shape({
  search: Yup.string().min(3),
});

export const UsersScreen: React.FC<any> = ({ navigation }) => {
  const dispatch = useAppDispatch();

  const userState = useAppSelector((state) => state.users);
  const { loading: usersLoading, users, currentPage, pageCount } = userState;

  const [initialLoad, setInitialLoad] = useState(true);
  const [currentQuery, setCurrentQuery] = useState<string>();

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    validationSchema: searchSchema,
    onSubmit: (values) => {
      search(values.search);
    },
  });

  useEffect(() => {
    dispatch(usersActions.getUsers({ page: 1, status: undefined }));
  }, []);

  useEffect(() => {
    dispatch(usersActions.getUsers({ page: currentPage, status: currentQuery }));
  }, [currentPage, currentQuery]);

  useEffect(() => {
    if (initialLoad && users.length > 0) {
      setInitialLoad(false);
    }
  }, [initialLoad, users]);

  const incrementPage = useCallback(() => {
    dispatch(usersActions.updateCurrentPage(currentPage + 1));
  }, [currentPage]);

  const decrementPage = useCallback(() => {
    if (currentPage > 1) {
      dispatch(usersActions.updateCurrentPage(currentPage - 1));
    }
  }, [currentPage]);

  const search = useCallback((query: string) => {
    setCurrentQuery(query);
  }, []);

  if (initialLoad) {
    return <SplashScreen />;
  }

  return (
    <ScrollView>
      <Box p={4}>
        <VStack space={4}>
          <FormControl isInvalid={formik.touched.search && formik.errors.search ? true : false}>
            <FormControl.ErrorMessage>
              {formik.touched.search && formik.errors.search ? formik.errors.search : null}
            </FormControl.ErrorMessage>
            <Input
              placeholder=""
              onChangeText={formik.handleChange('search')}
              autoCapitalize="none"
              returnKeyType="default"
              value={formik.values.search}
              InputRightElement={
                <Button
                  onPress={() => formik.handleSubmit()}
                  colorScheme="secondary"
                  isDisabled={usersLoading}
                  isLoading={usersLoading}
                >
                  SEARCH
                </Button>
              }
            />
          </FormControl>

          {usersLoading && (
            <Center>
              <ActivityIndicator color="#bbbbbb" />
            </Center>
          )}

          {!usersLoading && (
            <Box>
              <VStack space={4} mb={4}>
                {users.map((user) => {
                  return (
                    <Link href={`users/${user.id}`} key={user.id} width="100%">
                      <VStack width="100%" space={4}>
                        <HStack alignItems="center" justifyContent="space-between" width="100%">
                          <Box>
                            <Text style={{ color: user.active ? colors.secondary : colors.lightGrey }}>
                              {user.firstName} {user.lastName} ({user.email})
                            </Text>
                          </Box>
                          <Box>
                            <Feather name="chevron-right" size={25} style={{ color: colors.primary }} />
                          </Box>
                        </HStack>
                        <Divider />
                      </VStack>
                    </Link>
                  );
                })}
                {users.length === 0 && (
                  <Text style={rootStyles.centeredText} textTransform="uppercase" m={8}>
                    No users available.
                  </Text>
                )}
              </VStack>

              <HStack justifyContent="space-between" space={2}>
                <Button
                  flex={1}
                  justifyContent="flex-start"
                  onPress={() => decrementPage()}
                  variant="ghost"
                  disabled={currentPage <= 1}
                  leftIcon={
                    <Feather
                      name="chevron-left"
                      size={20}
                      style={{ color: currentPage <= 1 ? colors.lightGrey : colors.primary }}
                    />
                  }
                >
                  Previous
                </Button>
                <Button
                  flex={1}
                  justifyContent="flex-end"
                  onPress={() => incrementPage()}
                  variant="ghost"
                  disabled={currentPage >= pageCount}
                  rightIcon={
                    <Feather
                      name="chevron-right"
                      size={20}
                      style={{ color: currentPage >= pageCount ? colors.lightGrey : colors.primary }}
                    />
                  }
                >
                  Next
                </Button>
              </HStack>
            </Box>
          )}
        </VStack>
      </Box>
    </ScrollView>
  );
};
