import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Setting } from '../../models/data';
import { settingService } from '../../services';
import { SettingState } from './types';

export const getSettings = createAsyncThunk('settings/getSettings', async (query?: string) => {
  return settingService.getSettings(query);
});

export const getSetting = createAsyncThunk('settings/getSetting', async (id: string) => {
  return settingService.getSetting(id);
});

export const updateSetting = createAsyncThunk('settings/updateSetting', async (setting: Setting) => {
  return settingService.update(setting);
});

const initialState: SettingState = {
  currentSetting: null,
  settings: [],
  loading: false,
  error: null,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateLoading(state, { payload: { loading } }: PayloadAction<{ loading: boolean }>) {
      state.loading = loading;
    },

    setSettings(state, { payload: { settings } }: PayloadAction<{ settings: Setting[] }>) {
      state.settings = settings;
    },

    updateError(state, { payload: { error } }: PayloadAction<{ error: string | null }>) {
      state.error = error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.settings = action.payload;
      })
      .addCase(getSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load settings.';
      });

    builder
      .addCase(getSetting.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.currentSetting = action.payload;
      })
      .addCase(getSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to load setting.';
      });

    builder
      .addCase(updateSetting.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.currentSetting = action.payload;
      })
      .addCase(updateSetting.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Unable to update setting.';
      });
  },
});

export const settingsActions = {
  ...settingsSlice.actions,
  getSettings,
  getSetting,
  updateSetting,
};

export type SettingsSlice = {
  [settingsSlice.name]: ReturnType<typeof settingsSlice['reducer']>;
};
