import { AlertDialog, Button } from 'native-base';
import React, { useCallback } from 'react';
import { useDeferredPromise } from '../hooks';

export interface Alert {
  title: string;
  message: string;
}

export interface Confirm {
  title: string;
  message: string;
}

interface DialogContextValue {
  showAlert: (alert: Alert) => Promise<void>;
  showConfirm: (confirm: Confirm) => Promise<boolean>;
}

const DialogContext = React.createContext<DialogContextValue>({
  showAlert: async () => {},
  showConfirm: async () => false,
});

export const useDialog = () => React.useContext(DialogContext);

export interface DialogProviderProps extends React.PropsWithChildren {}

export const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
  const { defer: alertDefer, deferRef: alertDeferRef } = useDeferredPromise<void>();
  const { defer: confirmDefer, deferRef: confirmDeferRef } = useDeferredPromise<boolean>();
  const [activeAlert, setActiveAlert] = React.useState<Alert>(null);
  const [activeConfirm, setActiveConfirm] = React.useState<Confirm>(null);
  const [alertIsOpen, setAlertIsOpen] = React.useState(false);
  const [confirmIsOpen, setConfirmIsOpen] = React.useState(false);
  const alertCancelRef = React.useRef(null);
  const confirmCancelRef = React.useRef(null);

  const onAlertClose = useCallback(() => {
    setAlertIsOpen(false);
    alertDeferRef.resolve();
  }, [alertDeferRef]);

  const onConfirmClose = useCallback(
    (confirmed: boolean) => {
      setConfirmIsOpen(false);
      confirmDeferRef.resolve(confirmed);
    },
    [confirmDeferRef],
  );

  const showAlert = useCallback(
    async (theAlert: Alert) => {
      setActiveAlert(theAlert);
      setAlertIsOpen(true);

      return alertDefer().promise;
    },
    [alertDefer],
  );

  const showConfirm = useCallback(
    async (theConfirm: Confirm) => {
      setActiveConfirm(theConfirm);
      setConfirmIsOpen(true);

      return confirmDefer().promise;
    },
    [confirmDefer],
  );

  return (
    <DialogContext.Provider value={{ showAlert, showConfirm }}>
      {children}
      <AlertDialog leastDestructiveRef={alertCancelRef} isOpen={alertIsOpen} onClose={onAlertClose}>
        <AlertDialog.Content>
          <AlertDialog.Header>{activeAlert?.title}</AlertDialog.Header>
          <AlertDialog.Body>{activeAlert?.message}</AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button colorScheme="primary" onPress={onAlertClose}>
                Okay
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
      <AlertDialog leastDestructiveRef={confirmCancelRef} isOpen={confirmIsOpen} onClose={() => onConfirmClose(false)}>
        <AlertDialog.Content>
          <AlertDialog.Header>{activeConfirm?.title}</AlertDialog.Header>
          <AlertDialog.Body>{activeConfirm?.message}</AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button
                variant="unstyled"
                colorScheme="coolGray"
                onPress={() => onConfirmClose(false)}
                ref={confirmCancelRef}
              >
                Cancel
              </Button>
              <Button colorScheme="primary" onPress={() => onConfirmClose(true)}>
                Okay
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </DialogContext.Provider>
  );
};
